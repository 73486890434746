import {createEffect} from "effector";
import {getRequestList} from "../api";
import { getPositionInfo } from "../../position-add/api";
import { getOrganizationInfo } from "../../organization-add/api";

export const fetchRequestList = createEffect({
    handler: getRequestList
})

export const feftchRequestPosition = createEffect({
    handler: getPositionInfo
})

export const fetchRequestOrg = createEffect({
    handler: getOrganizationInfo
})
