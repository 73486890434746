import {createEffect} from "effector";
import {getStationList, getStationInfo, getStationSubList} from "../../api/station-api";

export const fetchStationList = createEffect({
  handler: getStationList
})

export const fetchStationInfo = createEffect({
  handler: getStationInfo
})

export const fetchStationSubList = createEffect({
  handler: getStationSubList
})