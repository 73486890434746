import {createEvent} from "effector";
import {fetchRequestList, feftchRequestPosition, fetchRequestOrg} from "./effects";

export const requestListMount= createEvent()
export const requestPageSizeChange = createEvent()
export const requestPositionMount = createEvent()
export const requestOrganizationMount = createEvent()

requestListMount.watch(fetchRequestList)
requestPositionMount.watch(feftchRequestPosition)
requestOrganizationMount.watch(fetchRequestOrg)
