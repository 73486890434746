import React, { useState } from 'react';
import Popover from "react-tiny-popover";
import { PopConfirmContent } from "../../../components/pop-confirm/atoms";
import { EditSvg, MoreHorizontalSvg, TrashSvg } from "../../../media";
import { withRouter } from "react-router-dom";
import { removeCategory } from "../../sectors/api";
import { deleteConfirm } from "../../../ui/molecules";
import { notiPopup } from '../../../components/notification';


const PopoverItemView = (props) => {
    const {
        id,
        history,
        match,
        updateList
    } = props;

    // console.log('dddd', id)

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const removeSector = () => {
        removeCategory(id)
            .then(response => {
                if (response.status === 200) {
                    updateList();
                    notiPopup(
                        'success',
                        'Перегон удален.'
                    )
                }
            })
            .catch(error => {
                console.error(error.response.data);
                notiPopup(
                    'error',
                    'Ошибка при удалении, попробуйте позже'
                )
            })
    };

    return (
        <Popover
            isOpen={isPopoverOpen}
            onClickOutside={() => setIsPopoverOpen(false)}
            position={'bottom'} // preferred position
            content={(
                <PopConfirmContent>
                    <span onClick={() => history.push(`/sections/${match.params.id}/sub/${id}`)}>
                        <EditSvg />
                        Редактировать
                    </span>
                    <span className='delete'
                        onClick={() => deleteConfirm(removeSector)}
                    >
                        <TrashSvg />
                        Удалить
                    </span>
                </PopConfirmContent>
            )}
        >
            <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                <MoreHorizontalSvg />
            </div>
        </Popover>
    )
};

export const PopoverItem = withRouter(PopoverItemView);
