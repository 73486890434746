import styled from 'styled-components';

import {Table} from 'antd';
import ReactTable from "react-table";
import 'react-table/react-table.css'

export const TableStyle = styled(ReactTable)`
  border: none;
  &.ReactTable{
    background: transparent;
    border: none;
      .rt{
        &-thead{
          &.-header{
            box-shadow: none;
          }
          .rt{
            &-tr{
              text-align: left;
            }
            &-th{
              border: none;
              font-weight: bold;
              font-size: 12px;
              line-height: 16px;
              text-transform: uppercase;
              color: #8F9BB3;
              padding: 15px 24px;
            }
          }
        }
        &-tbody{
          border: 1px solid #EDF1F7;
          background: #ffffff;
          border-radius: 8px;
          .rt{
            &-tr{
              &-group{
                border-bottom: 1px solid #EDF1F7;
              }
            }
            &-td{
              border-right: none;
              padding: 15px 24px;
              font-weight: 600;
              font-size: 14px;
              line-height: 24px;
              color: #222B45;
            }
          }
        }
      }
  }
`;


export const AntTable = styled(Table)`
  display: flex;
  width: 100%;
  flex-direction: column;

  .ant-table thead > tr > th {
    color: #8F9BB3;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    border: none;
    @media(max-width: 1440px){
      font-size: 11px;
      padding: 12px 14px;
    }
  }

   
  .ant{
    &-table{
      &-thead{
        & > tr{
          & > th{
            color: #8F9BB3;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            border: none;
          }
        }
      }
      &-tbody{
        border-collapse: collapse;
        box-shadow: 0 0 0 1px #EDF1F7;
        border-radius: 8px;
        overflow: hidden;
        background: #ffffff;
        & > tr{
          &:hover, &:focus{
            & > td{
              background: #EDF1F7 !important;
            }
          }
          & > td{
            border-bottom: 1px solid #EDF1F7;
            vertical-align: ${({verticalAlign}) => verticalAlign && verticalAlign === 'top' ? 'top' : 'middle'};
            padding: 0 !important;

            a{
              color: #222B45;
              padding: 16px;
              display: block;
              font-size: 14px;
              line-height: 24px;
              font-weight: 600;
              div{
                min-height: 24px;
              }
              @media (max-width: 1440px) {
                padding: 12px 14px;
                line-height: 1.3; 
                font-size: 11px;
              }
            }
            span{
              color: #222B45;
              padding: 16px;
              display: block;
              font-size: 14px;
              line-height: 24px;
              font-weight: 600;
              @media (max-width: 1440px) {
                padding: 12px 14px;
                line-height: 1.3; 
                font-size:11px
              }
            }
          }
        }
      }
    }
  }
`;

export const TableBordered = styled(Table)` 
  .ant-table tbody > tr > td {
     padding: 6px 16px;
     border: 1px solid #E4E9F2;
     
     &:hover, &:focus {
      background: #EDF1F7;
    }
  }
  
  .ant-table table{
    border: 1px solid #E4E9F2;
    border-collapse: collapse;
  }
  
  .ant-table tbody > tr > td div{
    display: flex;
    flex-direction: column;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    justify-content: center;
    align-content: center;
    text-align: center;
    position: relative;
    padding: 4px 0;
      &:after {
            content: '';
            display: block;
            width: 70px;
            height: 1px;
            background-color:#E4E9F2;
            position: absolute;
            bottom: -0.5px;
            left: 50%;
            transform: translateX(-50%);
          }
          &:last-child:after {
            content: unset;
          }
  }
  
  .ant-table thead > tr > th{
    padding: 12px 16px;
    background-color: #fff;
    border: 1px solid #E4E9F2;
    border-bottom: 2px solid #E4E9F2;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    color: #8F9BB3;
    text-transform: uppercase;
    text-align: center;
    &:hover, &:focus {
      background: #EDF1F7;
    }
  }
`
