import {useCallback, useEffect, useState} from "react";
import {createStation, getStationInfo, updateSection} from "../../api/station-api";
import {useHistory, useParams} from "react-router-dom";
import {getSectorInfo} from "../../api/sector-api";
import { notiPopup } from "../../components/notification";

export const useStationForm = () => {
  const {id, stationId} = useParams()
  const {push} = useHistory()

  const [name, setName] = useState('')
  const [coverage, setCoverage] = useState(0)
  const [sectorInfo, setSectorInfo] = useState({})
  const [loading, setLoading] = useState(false)
  const [startKm, setStartKm] = useState(0)
  const [startMeter, setStartMeter] = useState(0)
  const [stopKm, setStopKm] = useState(0)
  const [stopMeter, setStopMeter] = useState(0)

  console.log(name);

  const clearState = () => {
    setName('')
    setCoverage(0)
    setStartKm(0)
    setStartMeter(0)
    setStopKm(0)
    setStopMeter(0)
  }

  const stationInfo = useCallback(async () => {
    if (stationId) {
      try {
        const res = await getStationInfo(stationId)
        if (res.status === 200) {
          const data = res.data
          setName(data.name)
          setCoverage(data.coverage)
          setStartKm(data.parentStartKm)
          setStartMeter(data.parentStartMeter)
          setStopKm(data.parentStopKm)
          setStopMeter(data.parentStopMeter)
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [stationId])


  const addStation = useCallback(async (data) => {
    setLoading(true)
    try {
      const res = await createStation(data)
      if (res.status === 200) {
        setLoading(false)
        clearState()
        notiPopup(
          'success',
          `Станция  добавлена`
      )
      }
    } catch (e) {
      console.log(e);
      setLoading(false)
      notiPopup(
        'error',
        `Ошибка при добавлении, попробуйте позже.`
        )
    }
  }, [])

  const editStation = useCallback(async (data) => {
    setLoading(true)
    try {
      const res = await updateSection(data)
      if (res.status === 200) {
        setLoading(false)
        clearState()
        notiPopup('success', 'Станция отредактирована')
        push(`/sections/${id}/stations`)
      }
    } catch (e) {
      console.log(e);
      setLoading(false)
      notiPopup(
        'error',
        `Ошибка при редактировании, попробуйте позже.`
        )
    }
  }, [push, id])

  const sendForm = useCallback(() => {
    const data = {
      id: id,
      data: {
        coverage: Number(coverage),
        name: name,
        parent: sectorInfo,
        parentStartKm: Number(startKm),
        parentStartMeter: Number(startMeter),
        parentStopKm: Number(stopKm),
        parentStopMeter: Number(stopMeter)
      }
    }

    if (stationId) {
      data['data']['id'] = stationId
      editStation(data)
    } else {
      addStation(data)
    }

  }, [sectorInfo, coverage, name, id, addStation, stationId, editStation, startKm, startMeter, stopKm, stopMeter])


  useEffect(() => {
    stationInfo()
  }, [stationInfo])


  const sectorInfoData = useCallback(async() => {
      if(id){
        try {
          const res = await getSectorInfo(id)
          if(res.status === 200) {
            setSectorInfo(res.data);
          }
        }catch(e) {
          console.log(e);
        }
      }
  }, [id])

  useEffect(() => {
    sectorInfoData()
  }, [sectorInfoData]);

  return {
    coverage,
    setCoverage,
    name,
    setName,
    sendForm,
    loading,
    startKm,
    setStartKm,
    startMeter,
    setStartMeter,
    stopKm,
    setStopKm,
    stopMeter,
    setStopMeter,
    sectorInfo
  }
}