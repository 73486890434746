import {createEvent} from "effector";
import {fetchAllOrgUserList, fetchSignedUserInfo, fetchUserList} from "./effect";

/*create events*/
export const signedUserInfoMount = createEvent();
export const allOrgUserListMount = createEvent();
export const deleteUserRole = createEvent()
export const userListMount = createEvent()

/*watch effects*/
signedUserInfoMount.watch(fetchSignedUserInfo)
allOrgUserListMount.watch(fetchAllOrgUserList)
userListMount.watch(fetchUserList)