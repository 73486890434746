import React, { useState, useEffect, useCallback } from 'react'
import { PopoverItem, RequestValue } from "../moleculas";
import { AntTable, Container } from "../../../ui/atoms";
import { TableItem } from "../atoms";
import { useHistory } from "react-router-dom";
import { useStore } from "effector-react";
import { $request, requestListMount, requestPageSizeChange, requestPositionMount } from "../models";
import { ArowRightSvg } from "../../../media";
import { REQUEST_STATUS } from "../hook";
import { requestOrganizationMount } from '../models/events';

export const RequestContent = ({sortValue}) => {
    const { push, location } = useHistory()
    const { $requestList, $requestSettings } = useStore($request)
    const [start, setStart] = useState(0);
    const [pageSize, setPageSize] = useState($requestSettings.request.pageSize ? $requestSettings.request.pageSize : 20);
    const [page, setPage] = useState(1);

    useEffect(() => {
        const positionIds = $requestList.positionIds
        const orgIds = $requestList.organizationIds
        if (positionIds.length > 0) {
            for (let i = 0; i < positionIds.length; i++) {
                requestPositionMount(positionIds[i])
            }
        }

        if (orgIds.length > 0) {
            for (let i = 0; i < orgIds.length; i++) {
                requestOrganizationMount(Number(orgIds[i]))
            }
        }
    }, [$requestList])

    const updateList = useCallback(() => {
        const params = {
            length: pageSize,
            start: start,
            sort: sortValue.id
        };
        requestListMount(params)
    }, [pageSize, start, sortValue])

    useEffect(() => {
        updateList()
    }, [updateList])


    const columns = [
        {
            title: 'Изменить',
            dataIndex: 'changed',
            key: 'changed',
            width: 200,
            render: (changed) => (<TableItem>{changed === 'position' ? 'Запрос на изменение должности' : 'Запрос на изменение организации'}</TableItem>)
        },
        {
            title: 'Детали',
            dataIndex: 'detail',
            key: 'detail',
            width: 70,
            render: (detail, data) => (<TableItem>{data.oldValueTitle} <ArowRightSvg /> <RequestValue type={data.changed} id={data.newValue} /></TableItem>)
        },
        {
            title: 'Имя, Фамилия',
            dataIndex: 'user',
            key: 'user',
            width: 70,
            render: (user) => (<TableItem>{user}</TableItem>)
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            render: (status, data) => (<TableItem type={status}>
                {status === REQUEST_STATUS.NEW || status === null ? 'Ждет обработки' : status === REQUEST_STATUS.SATISFIED ? 'Одобрено' : 'Отказано'}
            </TableItem>)
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            width: 30,
            render: (id, data) => data.status === REQUEST_STATUS.NEW || data.status === null ? (<PopoverItem updateList={updateList} id={data.key} />) : ''
        }
    ];

    function changePagination(page) {
        if (page !== 1) {
            push({
                pathname: '/sections',
                search: `page=${page}`
            })
        } else {
            push('/sections')
        }
    }

    function changeShowSize(current, size) {
        setPageSize(size)

        const payload = { pageSize: size }
        requestPageSizeChange(payload)
    }

    useEffect(() => {
        const paramsString = location.search;
        const searchParams = new URLSearchParams(paramsString);
        if (searchParams.has('page')) {
            const _page = Number(searchParams.get('page'))
            setPage(_page)
            setStart(((_page - 1) * pageSize))

        } else {
            setStart(0)
            setPage(1)
        }
    }, [location.search, pageSize])

    return (
        <Container>
            <AntTable
                loading={$requestList.loading}
                columns={columns}
                dataSource={$requestList.data}
                pagination={
                    {
                        pageSize: pageSize,
                        pageSizeOptions: ['20', '30', '40', '50', '60', '70', '80', '90', '100'],
                        total: $requestList.result.recordsFiltered,
                        onChange: changePagination,
                        onShowSizeChange: changeShowSize,
                        current: page,
                        showSizeChanger: true,
                        showLessItems: true
                    }
                }
            />
        </Container>
    )
}
