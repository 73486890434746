import React, { useEffect, useState } from "react";

import { UploadFile } from '../../../components/download'
import { GridItem, Button, ButtonCenter } from "../../../ui/atoms";
import { InputSystem } from "../../../ui/molecules";
import { withRouter } from "react-router-dom";
import {
    createSectordApi,
    getSectorInfo,
    getSectorsList
} from "../api";
import { SelectSystem } from "../../../ui/molecules/select";
import { FormSection, GridBasic, Container } from '../../../ui/atoms';
import { notiPopup } from "../../../components/notification";
import { useLastLocation } from "react-router-last-location";


const SectorsFormGridItemView = (props) => {
    const {
        history,
        match
    } = props;

    const lastLocation = useLastLocation()

    //data
    const [name, setName] = useState(undefined);
    const [length, setLength] = useState(undefined);
    const [startPoint, setStartPoint] = useState(0);
    const [endPoint, setEndPoint] = useState(0);
    const [uploadFile, setUploadFile] = useState([]);
    const [sectorList, setSectorList] = useState([]);
    const [sectorId, setSectorId] = useState(undefined);

    const [search, setSearch] = useState(undefined)


    //status
    const [loading, setLoading] = useState(false);
    
    // console.log(match);
    const createSector = () => {

        const data = {
            length: Number(length),
            name: name,
            startPoint: Number(startPoint),
            countdownPoint: Number(endPoint),
            uploads: uploadFile,
            origin: {
                id: sectorId
            }
        };

        if (match.params.id) {
            data.id = Number(match.params.id)
        }

        setLoading(true);
        createSectordApi(data)
            .then(response => {
                if (response.status === 200) {
                    setLoading(false);
                    notiPopup(
                        'success',
                        `Направления ${match.params.id ? 'редактирована' : 'добавлена'}`
                    )

                    setSectorId(undefined);
                    setEndPoint(0);
                    setLength('');
                    setName('');
                    setStartPoint(0);
                    if (match.params.id) {
                        
                        if (lastLocation) {
                            history.push({
                                pathname: lastLocation.pathname,
                                search: lastLocation.search
                            })
                        } else {
                            history.push('/sections')
                        }
                    }
                }
            })
            .catch(error => {
                setLoading(false);
                notiPopup(
                    'error',
                    `Ошибка при ${match.params.id ? 'редактировании' : 'добавлении'}, попробуйте позже`
                )
            })
    };


    useEffect(() => {
        if (match.params.id) {
            getSectorInfo(match.params.id)
                .then(response => {
                    if (response.status === 200) {
                        const data = response.data;
                        setUploadFile(data.uploads.map(item => Number(item)))
                        setEndPoint(data.countdownPoint);
                        setLength(data.length);
                        setName(data.name);
                        setStartPoint(data.startPoint);
                        setSectorId(data.origin.id);
                        setSearch(data.origin.name)
                    }
                })
                .catch(error => console.log(error.response))
        }
    }, [match.params.id]);


    useEffect(() => {
        const params = {
            'search.value': search
        }
        getSectorsList(params)
            .then(response => {
                if (response.status === 200) {
                    const result = response.data.data
                    let data = [];
                    for (let i = 0; i < result.length; i++) {
                        data.push({
                            label: result[i].name,
                            value: result[i].id
                        })
                    }
                    setSectorList(data)
                }
            })
    }, [search]);

    return (
        <Container>

            <FormSection>
                <GridBasic perColumn={2} rowGap={40} columnGap={24}>
                    <GridItem>
                        <InputSystem
                            disabled={loading}
                            value={name}
                            change={setName}
                            type='text'
                            placeholder='Введите название направления'
                            label='Название'
                        />
                    </GridItem>
                    <GridItem>
                        <InputSystem
                            disabled={loading}
                            value={length}
                            change={setLength}
                            type='text'
                            placeholder='Длина направления'
                            label='Длина'
                        />
                    </GridItem>
                    <GridItem>
                        <SelectSystem
                            showSearch={true}
                            onSearch={setSearch}
                            disabled={loading}
                            placeholder='Выберите направление'
                            options={sectorList}
                            label='Стыковка с другими направлениями'
                            value={sectorId}
                            change={setSectorId}
                            style={{ padding: 0 }}
                        />
                    </GridItem>
                    <GridItem>
                        <UploadFile
                            disabled={loading}
                            change={setUploadFile}
                            value={uploadFile}
                            type='text'
                            label='Загрузка файла'
                        />
                    </GridItem>
                    <GridItem>
                        <InputSystem
                            disabled={loading}
                            change={setStartPoint}
                            value={startPoint}
                            type='sector'
                            placeholder='Введите точку начала стыковки'
                            label='точка начала'
                        />
                    </GridItem>
                    <GridItem>
                        <InputSystem
                            max={999}
                            disabled={loading}
                            value={endPoint}
                            change={setEndPoint}
                            type='sector'
                            placeholder='Введите точку отсчета (в метрах)'
                            label='точка отсчёта'
                        />
                    </GridItem>
                </GridBasic>
                <ButtonCenter>
                    <Button onClick={() => history.push('/categories')} type='link'>Отмена</Button>
                    <Button
                        loading={loading}
                        disabled={
                            !name
                            || !length
                        }
                        onClick={() => createSector()}>Добавить</Button>
                </ButtonCenter>
            </FormSection>

        </Container>)
};

export const SectorsFormBlock = withRouter(SectorsFormGridItemView);
