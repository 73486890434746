import React, {useEffect, useState} from 'react';
import {BodyHead} from "../../../components/body-head";
import {AddUserOrganization} from "../organisms";
import {CategoriesSection} from "../../categories/atoms";
import {withRouter} from "react-router-dom";
import {getOrganizationInfo} from "../api";
import { UserPermisson } from '../organisms/user-permission';



const OrganizationUserAddView = (props) => {
    const {
        match
    } = props;

    const [ orgName, setOrgName ] = useState(false);

    useEffect(() => {
        if (match.params.id){
            getOrganizationInfo(match.params.id)
                .then(response => {
                    if (response.status === 200){
                        setOrgName(response.data.name);
                    }
                })
                .catch(error => {
                    console.error(error.response)
                })
        }
    }, [match]);


    return (
        <CategoriesSection>
            <BodyHead
                back={`organization/${match.params.id}/user`}
                title={`Добавить специалиста в ${orgName ? orgName : 'организации'}`}
            />
            <UserPermisson/>
        </CategoriesSection>
    )
};


export const OrganizationUserAdd = withRouter(OrganizationUserAddView);
