import {createEffect} from "effector";
import {getAllOrgUserList, getSignedUserInfo, getUserList} from "../../api/user-api";

export const fetchSignedUserInfo = createEffect({
    handler: getSignedUserInfo
});

export const fetchAllOrgUserList = createEffect({
    handler: getAllOrgUserList
})

export const fetchUserList = createEffect({
    handler: getUserList
})