import { combine, createStore } from "effector";
import { fetchStationList, fetchStationInfo, fetchStationSubList } from "./effects";
import { numberFormat } from "../../helpers";

const $stationList = createStore({ loading: true, data: [], result: [], filtered: [], error: false })
  .on(fetchStationList.pending, (state, pending) => {
    return {
      ...state,
      loading: pending
    }
  })
  .on(fetchStationList.finally, (state, res) => {
    return {
      ...state,
      result: res.result.data,
      data: res.result.data.map(item => ({
        key: item.id,
        id: item.id,
        name: item.name ? item.name : '',
        coverage: `${item.coverage} м`,
        sector: item.parent.name,
        start: item.parentStartKm && item.parentStartMeter ? `${item.parentStartKm} км ${item.parentStartMeter} м` : '',
        end: item.parentStopKm && item.parentStopMeter ? `${item.parentStopKm} км ${item.parentStopMeter} м`: ''
      })),
      filtered: res.result.data.map(item => ({
        value: item.id,
        label: item.name,
      })),
      error: false
    }
  })
  .on(fetchStationList.fail, (state, error) => {
    return {
      ...state,
      error: error.response.error,
      data: [],
      result: []
    }
  })

const $stationInfo = createStore({ loading: false, data: {}, error: false })
  .on(fetchStationInfo.pending, (state, pending) => ({ ...state, loading: pending }))
  .on(fetchStationInfo.fail, (state, error) => ({ ...state, data: {}, error: error.response }))
  .on(fetchStationInfo.finally, (state, res) => ({ ...state, error: false, data: res.result.data }))

const $stationSubList = createStore({ loading: false, data: [], error: false })
  .on(fetchStationSubList.pending, (state, pending) => ({ ...state, loading: pending }))
  .on(fetchStationSubList.fail, (state, error) => ({ ...state, error: error.response, data: [] }))
  .on(fetchStationSubList.finally, (state, res) => ({
    ...state, error: false, data: res.result.data.map(item => ({
      key: item.id,
      id: item.id,
      popover: item.id,
      name: { name: item.name, id: item.id },
      length: { name: numberFormat(item.length), id: item.id },
      qwerty: { name: 'Тест', id: item.id },
      startPoint: {
        name: item.startPoint,
        id: item.id
      },
      station: item.station ? item.station.name : '',
      countdownPoint: {
        name: item.countdownPoint,
        id: item.id
      },
    }))
  }))



export const $station = combine({
  $stationList,
  $stationInfo,
  $stationSubList
})