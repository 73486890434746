import React, {useRef, useState} from "react";
import {Modal} from 'antd';
import {
    ModalBody,
    ModalCustom,
    ModalFooter,
    ModalHeader,
    Button,
    ModalFooterWrapper, 
    MiniModalCustom, 
    LargeModalCustom, 
    DropWrapper, 
    CropWrapper, 
    CropModalCustom
} from "../atoms";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import Dropzone from "react-dropzone";
import {
    base64StringtoFile,
    extractImageFileExtensionFromBase64,
    image64toCanvasRef
} from '../../utils/crop-utils'
import ReactCrop from "react-image-crop";
import {AlertTriangleSvg} from "../../media";
import 'moment/locale/ru';


const {confirm} = Modal;

export const ModalTariff = (props) => {
    const {
        statusModal,
        setStatusModal,
        title,

    } = props;

    return (
        <ModalCustom
            title={title}
            visible={statusModal}
            headStyle={{display: 'none'}}
            footer={null}
            onCancel={() => setStatusModal(false)}
        >
            <ModalHeader>
                <h1>Выберите карту</h1>
            </ModalHeader>
            <ModalBody>

            </ModalBody>
            <ModalFooter>
                <ModalFooterWrapper>
                    <Button
                        type='link'
                        onClick={() => setStatusModal(false)}
                    >
                        Отменить
                    </Button>
                    <Button>
                        Оплатить
                    </Button>
                </ModalFooterWrapper>
            </ModalFooter>
        </ModalCustom>
    )
};

export const UserProfileModal = (props) => {
    const {
        component,
        statusModal,
        setStatusModal,
        title
    } = props;

    return (
        <MiniModalCustom
            title={title}
            visible={statusModal}
            headStyle={{display: 'none'}}
            footer={null}
            onCancel={() => setStatusModal(false)}
        >
            <ModalBody style={{minHeight: 'unset'}}>
                {component}
            </ModalBody>
            {/*<ModalFooter>*/}
            {/*    <ModalFooterWrapper>*/}
            {/*        <Button*/}
            {/*              type='link'*/}
            {/*              onClick={() => setStatusModal(false)}*/}
            {/*        >*/}
            {/*            Отменить*/}
            {/*        </Button>*/}
            {/*        <Button>*/}
            {/*            Сохранить*/}
            {/*        </Button>*/}
            {/*    </ModalFooterWrapper>*/}
            {/*</ModalFooter>*/}
        </MiniModalCustom>
    )
};

export const UserListModal = (props) => {
    const {
        statusModal,
        setStatusModal,
        title,
        component
    } = props


    return (
        <LargeModalCustom
            title={title}
            visible={statusModal}
            headStyle={{display: 'none'}}
            footer={null}
            onCancel={() => setStatusModal(false)}
        >
            <ModalBody style={{minHeight: 'unset'}}>
                {component}
            </ModalBody>
        </LargeModalCustom>
    )
}

export const deleteConfirm = (action,) => {
    confirm({
        title: 'Дейтвительно хотите удалить?',
        icon: <ExclamationCircleOutlined/>,
        okText: 'Да',
        okType: 'danger',
        cancelText: 'Нет',
        onOk() {
            action()
        },
        onCancel() {
        },
    });
}

export const ImageUploadModal = (props) => {

    const {statusModal, setStatusModal, title, setFileValue} = props;
    const [imgSrc, setImgSrc] = useState(null)
    const [imgSrcExt, setImgSrcExt] = useState(null)
    const [crop, setCrop] = useState({unit: '%', aspect: 1 / 1, width: 50})
    const [error, setError] = useState(null)

    const imagePreviewCanvasRef = useRef(null)

    const imageMaxSize = 500000 // bytes
    const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif'
    const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {
        return item.trim()
    });

    const verifyFile = (files) => {
        if (files && files.length > 0) {
            const currentFile = files[0].file !== undefined ? files[0].file : files[0];
            const currentFileType = currentFile.type;
            const currentFileSize = currentFile.size;

            // console.log(currentFile);
            if (currentFileSize > imageMaxSize) {
                // alert("This file is not allowed. " + currentFileSize + " bytes is too large")
                setError('максимум размер изображение 300x300');
                return false

            }
            if (!acceptedFileTypesArray.includes(currentFileType)) {
                setError('Этот файл не разрешен. Разрешены только изображения');
                return false
            }
            return true
        }
    };


    const handleOnDrop = (files, rejectedFiles) => {
        if (rejectedFiles && rejectedFiles.length > 0) {
            verifyFile(rejectedFiles)
        }

        if (files && files.length > 0) {
            const isVerified = verifyFile(files)
            if (isVerified) {
                const currentFile = files[0]
                const myFileItemReader = new FileReader()
                myFileItemReader.onload = (e) => {
                    const myResult = e.target.result
                    setImgSrc(myResult)
                    setImgSrcExt(extractImageFileExtensionFromBase64(myResult))
                    return myResult
                }

                myFileItemReader.readAsDataURL(currentFile)
            }
        }

    }


    const handleOnCropComplete = (crop, pixelCrop) => {
        const canvasRef = imagePreviewCanvasRef.current
        image64toCanvasRef(canvasRef, imgSrc, crop)

    }

    const handleOnCropChange = (crop) => {
        setCrop(crop)
    }

    const createCroppedImage = (e) => {
        e.preventDefault()
        if (imgSrc) {
            const canvasRef = imagePreviewCanvasRef.current
            const imageData64 = canvasRef.toDataURL('image/' + imgSrcExt)
            const myFilename = "previewFile." + imgSrcExt
            const myNewCroppedFile = base64StringtoFile(imageData64, myFilename)
            console.log(myNewCroppedFile);

            setFileValue(myNewCroppedFile)
            setStatusModal(false)
            handleClearToDefault()
            // downloadBase64File(imageData64, myFilename)
        }
    }

    const handleClearToDefault = () => {
        setImgSrc(null)
        setImgSrcExt(null)
        setCrop({unit: '%', aspect: 1 / 1, width: 50})
        setStatusModal(false)
        setError(null)
    }

    return (
        <CropModalCustom
            title={title}
            visible={statusModal}
            headStyle={{display: 'none'}}
            footer={null}
            onCancel={() => setStatusModal(false)}
        >
            <ModalHeader>
                <h1>{title}</h1>
            </ModalHeader>
            <ModalBody>
                {imgSrc !== null
                    ?
                    <CropWrapper>
                        <ReactCrop
                            src={imgSrc}
                            crop={crop}
                            onComplete={handleOnCropComplete}
                            onChange={handleOnCropChange}
                        />
                        <canvas style={{display: 'none'}} ref={imagePreviewCanvasRef}>
                        </canvas>
                    </CropWrapper>
                    :
                    <Dropzone onDrop={handleOnDrop} accept={acceptedFileTypes} multiple={false}
                              maxSize={imageMaxSize}>
                        {({getRootProps, getInputProps}) => (
                            <section>
                                <DropWrapper {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <div>Перетащите файл сюда</div>
                                    <span>или</span>
                                    <Button style={{marginBottom: 10}}>Выберите локальный файл</Button>
                                    {error !== null &&
                                    <strong>
                                        <AlertTriangleSvg/>
                                        {error}
                                    </strong>
                                    }
                                </DropWrapper>
                            </section>
                        )}
                    </Dropzone>

                }

            </ModalBody>
            <ModalFooter>
                <ModalFooterWrapper>
                    <Button
                        type='link'
                        onClick={handleClearToDefault}
                    >
                        Отменить
                    </Button>
                    <Button
                        onClick={createCroppedImage}
                    >
                        Применить
                    </Button>

                </ModalFooterWrapper>
            </ModalFooter>
        </CropModalCustom>
    )
};
