import React, { Fragment, useEffect } from 'react'
import { BodyHead } from "../../../components/body-head";
import { useParams } from 'react-router-dom'
import { StationSubList } from "../organisms";
import { stationInfoMount, $station, stationSubListMount } from '../../../models/station-model';
import { useStore } from 'effector-react';

export const StationSub = () => {
    const {id, stationId} = useParams()

    const {$stationInfo} = useStore($station)

    useEffect(() => {
        stationInfoMount(stationId)
        stationSubListMount(stationId)
    }, [stationId])
    
    return (
        <Fragment>
            <BodyHead goBack={false} back={`sections/${id}/stations`} title={$stationInfo.data.name ? $stationInfo.data.name : stationId } />
            <StationSubList />
        </Fragment>
    )
}
