import { combine, createStore } from "effector";
import { fetchRequestList, feftchRequestPosition, fetchRequestOrg } from "./effects";
import Cookies from "js-cookie";
import { sectorPageSizeChange } from "../../sectors/model";

let storage_name = 'settings';
let storage = Cookies.get(storage_name);

const $requestList = createStore({ loading: false, data: [], result: {}, positionIds: [], organizationIds: [], error: false })
    .on(fetchRequestList.pending, (state, pending) => {
        return {
            ...state,
            loading: pending
        }
    })
    .on(fetchRequestList.finally, (state, response) => {
        if (response.error) {
            return {
                ...state,
                error: response.error.response,
                data: [],
                result: {}
            }
        } else {
            return {
                ...state,
                error: false,
                data: response.result.data.data.map(item => ({
                    key: item.id,
                    changed: item.domain,
                    oldValueTitle: item.applicant.position.title,
                    newValue: item.value,
                    user: `${item.applicant.name} ${item.applicant.sname}`,
                    status: item.status
                })),
                result: response.result.data,
                positionIds: response.result.data.data.reduce((acc, cur) => {
                    const i = acc.findIndex(m => m === cur.value);
                    if (!~i || !acc[i].checked) {
                        if (cur.domain === 'position') {
                            acc.push(cur.value);
                        }

                        if (~i) {
                            acc.splice(i, 1);
                        }
                    }
                    return acc;

                }, []),
                organizationIds: response.result.data.data.reduce((acc, cur) => {
                    const i = acc.findIndex(m => m === cur.value);
                    if (!~i || !acc[i].checked) {
                        if (cur.domain === 'organization') {
                            acc.push(cur.value);
                        }

                        if (~i) {
                            acc.splice(i, 1);
                        }
                    }
                    return acc;
                }, [])
            }
        }
    })

let storageData = storage !== undefined && JSON.parse(storage)
let sectorSettings = storageData.request !== undefined ? { ...storageData } : {
    ...storageData,
    request: {}
}

const $requestSettings = createStore(storage !== undefined ? {
    ...storageData,
    ...sectorSettings
} : { request: {} })
    .on(sectorPageSizeChange, (params, payload) => {
        const { request } = params
        const newSettings = { ...request, ...payload }
        return {
            ...params,
            request: newSettings
        }
    })

$requestSettings.watch((state) => {
    Cookies.set(storage_name, JSON.stringify(state))
})

const $RequestValueInfo = createStore({ loading: false, positionInfo: null, orgInfo: null, error: false })
    .on(feftchRequestPosition.pending, (state, pending) => {
        return {
            ...state,
            loading: pending
        }
    })
    .on(feftchRequestPosition.finally, (state, res) => {
        if (res.error) {
            return {
                ...state,
                positionInfo: null,
                error: res.error
            }
        } else {
            const { positionInfo } = state
            const newData = { ...positionInfo }
            newData[res.result.data.id] = res.result.data

            return {
                ...state,
                positionInfo: newData
            }
        }
    })
    .on(fetchRequestOrg.pending, (state, pending) => {
        return {
            ...state,
            loading: pending
        }
    })
    .on(fetchRequestOrg.finally, (state, res) => {
        if (res.error){
            return {
                ...state,
                error: res.error.response,
                orgInfo: null
            }
        }else {
            const { orgInfo } = state
            const newData = { ...orgInfo }
            newData[res.result.data.id] = res.result.data

            return {
                ...state,
                orgInfo: newData
            }
        }
    })


export const $request = combine({
    $requestList,
    $requestSettings,
    $RequestValueInfo
})
