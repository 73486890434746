import {httpGet} from "../api";


export const getSectorInfo = (id) => httpGet({
    url: `/sector/${id}`
});

export const getSubSectorList = ({id, params}) => httpGet({
    url: `/sector/list/${id}`, params
});

export const getSectorList = (params) => httpGet({
    url: '/sector/list', params
})