import React, { useState, useEffect } from 'react';
import { GridItem, Button, ButtonCenter, FormSection, Container, GridBasic } from "../../../ui/atoms";
import { DatePickerBlock, InputSystem } from "../../../ui/molecules";
import { SelectSystem } from "../../../ui/molecules/select";
import { withRouter } from "react-router-dom";
import { createApi } from "../api";
import { listCategoriesMounted } from "../model";
import { useStore } from "effector-react";
import { $isDataPending } from "../model";
import moment from "moment";
import { notiPopup } from '../../../components/notification';



const AddSubCategoryView = (props) => {
    const isDataPending = useStore($isDataPending);
    const {
        match
    } = props;

    const [name, setName] = useState(undefined);
    const [description, setDescription] = useState(undefined);
    const [attribute, setAttribute] = useState('LIST');
    const [list, setList] = useState(undefined);
    const [date, setDate] = useState(Date.now);
    const [long] = useState(false);
    const [double, setDouble] = useState(undefined);
    const [file, setFile] = useState(undefined);
    const [listOptions, setListOptions] = useState([]);

    function setConstraints() {
        if (attribute === 'LIST') {
            return { list: list }
        } else if (attribute === 'DATE') {
            return { date: date }
        } else if (attribute === 'LONG') {
            return { long: long }
        } else if (attribute === 'DOUBLE') {
            return { double: double }
        } else if (attribute === 'FILE') {
            return { file: file }
        }
    }
    const CreateCategories = () => {
        const data = {
            name: name,
            type: attribute,
            description: description,
            constraints: setConstraints()
        };
        createApi(match.params.id, data)
            .then(response => {
                if (response.status === 200) {
                    setName('');
                    setDescription('');
                    setAttribute('');
                    notiPopup(
                        'success',
                        'Подкатегория добавлена.'
                    )
                }
            })
            .catch(error => {
                console.error(error.response.data);
                notiPopup(
                    'error',
                    'Ошибка при добавлении, попробуйте позже.'
                )
            })

    };

    const data = [
        {
            value: 'TEXT',
            label: 'Текст'
        },
        {
            value: 'LONG',
            label: 'Цифры'
        },
        {
            value: 'DOUBLE',
            label: 'Дробные'
        },
        {
            value: 'LIST',
            label: 'Список'
        },
        {
            value: 'DATE',
            label: 'Дата'
        },
        {
            value: 'FILE',
            label: 'Файл'
        }
    ];


    useEffect(() => {
        if (isDataPending.length > 0) {
            let data = [];
            for (let i = 0; i < isDataPending.length; i++) {
                data.push({ value: isDataPending[i].id, label: isDataPending[i].name })
            }
            setListOptions(data);
        }
    }, [isDataPending]);

    useEffect(() => {
        listCategoriesMounted(match.params.id)
    }, [match.params.id]);

    return (
        <Container>
            <FormSection>
                <GridBasic perColumn={2} rowGap={40} columnGap={24}>
                    <GridItem>
                        <InputSystem
                            value={name}
                            change={setName}
                            type='text'
                            placeholder='Введите название'
                            label='Название'
                        />
                    </GridItem>
                    <GridItem>
                        <SelectSystem
                            value={attribute}
                            change={(e) => setAttribute(e)}
                            options={data}
                            label='Тип аттрибута'
                        />
                    </GridItem>
                    {
                        attribute !== 'TEXT' && attribute !== 'LONG' &&
                        <GridItem>
                            {
                                {
                                    'LIST':
                                        <SelectSystem
                                            value={list}
                                            change={(e) => setList(e)}
                                            options={listOptions}
                                            label='Список'
                                        />,
                                    'DATE': <DatePickerBlock
                                        value={moment(date)}
                                        setValue={setDate}
                                    />,
                                    'FILE':
                                        <SelectSystem
                                            value={file}
                                            change={(e) => setFile(e)}
                                            options={[{ value: 'jpg', label: 'JPG' }, { value: 'png', label: 'PNG' }]}
                                            label='Список'
                                        />,
                                    'DOUBLE':
                                        <SelectSystem
                                            value={double}
                                            change={(e) => setDouble(e)}
                                            options={[
                                                { value: '+', label: '+' },
                                                { value: '-', label: '-' },
                                                { value: '/', label: '/' },
                                                { value: ',', label: ',' },
                                                { value: '.', label: '.' },
                                                { value: 'x', label: 'x' },
                                            ]}
                                            label='Список'
                                        />,
                                }[attribute]
                            }

                        </GridItem>
                    }
                    <GridItem>
                        <InputSystem
                            value={description}
                            change={setDescription}
                            type='text'
                            placeholder='Введите описание'
                            label='Описание'
                        />
                    </GridItem>
                </GridBasic>
                <ButtonCenter>
                    <Button type='link'>Отмена</Button>
                    <Button
                        disabled={
                            !name || !description || !attribute
                        }
                        onClick={() => CreateCategories()}>Добавить</Button>
                </ButtonCenter>
            </FormSection>
        </Container>)
};
export const AddSubCategory = withRouter(AddSubCategoryView);
