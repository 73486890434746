import React, {Fragment} from 'react';
import {BodyHead} from "../../../components/body-head";
import {StationForm} from "../organisms";
import {useParams} from "react-router-dom";

export const StationEdit = () => {
  const {id} = useParams()
  return (
      <Fragment>
        <BodyHead title='Добавить Станцию' back={`sections/${id}/stations`}/>
        <StationForm/>
      </Fragment>
  )
}

