import React, {useEffect, useState, useCallback} from "react";
import {AntTable, Container} from "../../../ui/atoms";
import {useStore} from "effector-react";
import {$isDataPending, sectorSubPageMount} from "../model";
import {Link, withRouter} from "react-router-dom";
import {PopoverItem} from "../molecules";
import { subSectorPageSizeSettingsChange } from "../model/events";



const CategoriesSubListView = (props) => {
    const {
        match,
        history,
        location
    } = props;

    const {$sectorsSub, $subSectorSettings} = useStore($isDataPending);

    //data
    // const [data, setData] = useState([]);
    //params
    const [pageSize, setPageSize] = useState($subSectorSettings.subSector.pageSize ? $subSectorSettings.subSector.pageSize : 10);
    const [page, setPage] = useState(undefined);
    const [start, setStart] = useState(undefined);


    function changePagination(page) {
        if (page !== 1) {
            history.push({
                pathname: `/sections/${match.params.id}/sub`,
                search: `page=${page}`
            })
        } else {
            history.push(`/sections/${match.params.id}/sub`)
        }
    }

    function changeShowSize(current, size) {
        setPageSize(size)
        const payload = { pageSize: size }
        subSectorPageSizeSettingsChange(payload);
    }

    useEffect(() => {
        const paramsString = location.search;
        const searchParams = new URLSearchParams(paramsString);
        if (searchParams.has('page')) {
            const _page = Number(searchParams.get('page'))
            setPage(_page)
            setStart(((_page - 1) * pageSize))

        } else {
            setStart(0)
            setPage(1)
        }


    }, [location.search, pageSize])
    const updateList = useCallback(() => {
        const data = {
            id: match.params.id,
            params:{
                length: pageSize,
                start: start,
                all: true
            }
        };
        sectorSubPageMount(data);
    }, [match.params.id, start, pageSize]);

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: 70,
            render: id => (<Link to={`/sections/${match.params.id}/sub/${id}`}>{id}</Link>)
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            render: props => (<Link to={`/sections/${match.params.id}/sub/${props.id}`}>{props.name}</Link>)
        },
        {
            title: 'Длина',
            dataIndex: 'length',
            key: 'length',
            width: 100,
            render: props => (<Link to={`/sections/${match.params.id}/sub/${props.id}`}>{props.name}</Link>)
        },
        {
            title: 'Начало (км)',
            dataIndex: 'startPoint',
            key: 'startPoint',
            width: 130,
            render: props => (<Link to={`/sections/${match.params.id}/sub/${props.id}`}>{props.name}</Link>)
        },
        {
            title: 'Отсчёт (м)',
            dataIndex: 'countdownPoint',
            key: 'countdownPoint',
            width: 130,
            render: props => (<Link to={`/sections/${match.params.id}/sub/${props.id}`}>{props.name}</Link>)
        },
        {
            title: 'Главный путь',
            dataIndex: 'type',
            key: 'type',
            render: (name, data) => (<Link to={`/sections/${match.params.id}/sub/${data.id}`}>{name}</Link>)
        },
        {
            title: 'Станция',
            dataIndex: 'station',
            key: 'station',
            width: 250,
            render: (station, data) => (<Link to={`/sections/${match.params.id}/sub/${data.id}`}>{station}</Link>)
        },
        {
            title: '',
            dataIndex: 'popover',
            key: 'popover',
            width: 70,
            render: id => (<PopoverItem updateList={updateList} id={id}/>)
        }
    ];



    useEffect(() => {
        if (page){
            updateList()
        }
    }, [page, updateList]);

    


    return (
        <Container>
            <AntTable
                loading={$sectorsSub.loading}
                columns={columns}
                dataSource={$sectorsSub.data.data}
                pagination={
                    {
                        pageSize: pageSize,
                        pageSizeOptions: ['10', '20', '30', '40', '50'],
                        total: $sectorsSub.data.recordsTotal,
                        onChange: changePagination,
                        onShowSizeChange: changeShowSize,
                        current: page,
                        showSizeChanger: true,
                        showLessItems: true
                    }
                }
            />
        </Container>
    )
};

export const CategoriesSubList = withRouter(CategoriesSubListView);
