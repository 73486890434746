import {httpDelete, httpGet, httpPost, httpPut} from "../api";

export const createStation = ({id, data}) => httpPut({
  url: `/station/${id}`, data
})

export const getStationList = (sectorId) => httpGet({
  url: `/station/list/${sectorId}`
})

export const deleteStation = (stationId) => httpDelete({
  url: `/station/${stationId}`
})

export const getStationInfo = (stationId) => httpGet({
  url: `/station/${stationId}`
})

export const updateSection = ({id, data}) => httpPost({
  url: `/station/${id}`, data
})

export const getStationSubList = (stationId) => httpGet({
  url: `/sector/secondaryStages/${stationId}`
})