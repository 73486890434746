import React from 'react'
import {Route, Switch} from 'react-router-dom';
import {Body, Content, HightBlockSection, InlineFlex} from "../atoms";
import {Header} from "../../components/header";
import {Users} from "../../features/users";
import {Main} from "../../components/main";
import {CategoriesPage} from "../../features/categories";
import {SectionsPage} from '../../features/sectors';
import {PositionsPage} from "../../features/positions";
import {PositionsAdd} from "../../features/position-add";
import {Organization} from "../../features/organization";
import {OrganizationAdd} from "../../features/organization-add";
import {OrganizationInfo} from "../../features/oragnization-info";
import {OrganizationSectorAdd, OrganizationUserAdd} from "../../features/oragnization-info/template";
import {HistoryLogs} from "../../features/history-logs";
import {Tariff} from "../../features/tariff";
import {Request} from "../../features/request";


export const HightBlock = () => {
  return (
      <HightBlockSection>
        <Header/>
        <Content>
          <Main/>
          <Body>
            <Switch>
              <Route path="/users" component={Users}/>
              <Route path='/categories' component={CategoriesPage}/>
              <Route path='/sections' component={SectionsPage}/>
              <Route exact path='/positions' component={PositionsPage}/>
              <Route exact path='/positions/add' component={PositionsAdd}/>
              <Route exact path='/positions/:id' component={PositionsAdd}/>
              <Route exact path='/organization/:id/sector/add' component={OrganizationSectorAdd}/>
              <Route exact path='/organization' component={Organization}/>
              <Route exact path='/organization/:id/user/add' component={OrganizationUserAdd}/>
              <Route exact path='/organization/:id/user/:permissionId/edit' component={OrganizationUserAdd}/>
              <Route exact path='/organization/add' component={OrganizationAdd}/>
              <Route path='/organization/:id/edit' component={OrganizationAdd}/>
              <Route path='/organization/:id' component={OrganizationInfo}/>
              <Route path='/logs' component={HistoryLogs}/>
              <Route path='/tariff' component={Tariff}/>
              <Route path='/request' component={Request}/>
            </Switch>
          </Body>
        </Content>
      </HightBlockSection>
  )
};
