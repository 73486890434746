import styled, {keyframes} from 'styled-components';

const fadeInLeft = keyframes`
from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
`

export const MainSection = styled.div`
  max-width: 296px;
  height: calc(100vh - 80px);
  background: #FFFFFF;
  width: 100%;
  border-right: 1px solid #EDF1F7;
  flex-wrap: wrap;
  position: fixed;
  top: 80px;
  left: 0;
  z-index: 30;
  display: ${({visible}) => visible ? 'block' : 'none'};
  
  @media (max-width: 1440px) {
    top: 50px;
    height: calc(100vh - 50px);
    max-width: 200px;
  }
  
  @media (max-width: 1280px) {
    animation: ${fadeInLeft} .3s;
  }
`;


