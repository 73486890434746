import React, {useEffect, useState} from 'react';
import {GridItem, Button, ButtonCenter, FormSection, Container, GridBasic} from "../../../ui/atoms";
import {InputSystem} from "../../../ui/molecules";
import {UploadFile} from "../../../components/download/template";
import {createSectordApi} from "../../sectors-add/api";
import {withRouter} from "react-router-dom";
import {Checkbox, notification} from "antd";
import {getSectorInfo} from "../../sectors-sub/api";
import {useLastLocation} from "react-router-last-location";
import {notiPopup} from '../../../components/notification';
import {$station, stationListMount} from "../../../models/station-model";
import {useParams} from "react-router-dom";
import {SelectSystem} from "../../../ui/molecules/select";
import {useStore} from "effector-react";


const SectorSubFormGridItemView = (props) => {
  const {
    match,
    history
  } = props;

  const {id} = useParams()

  const {$stationList} = useStore($station)

  //status
  const [loading, setLoading] = useState(false);

  //data
  const [name, setName] = useState(undefined);
  const [length, setLength] = useState(undefined);
  const [startPoint, setStartPoint] = useState(0);
  const [endPoint, setEndPoint] = useState(0);
  const [uploadFile, setUploadFile] = useState([]);
  const [parentName, setParentName] = useState(undefined);
  const [maxLengthSector, setMaxLengthSector] = useState(false);
  const [stationId, setStationId] = useState(undefined)
  const [secondStage, setSecondStage] = useState(false)

  const lastLocation = useLastLocation();


  const createSector = () => {

    const data = {
      length: length,
      name: name,
      startPoint: startPoint,
      countdownPoint: endPoint,
      uploads: uploadFile,
      parent: {id: Number(match.params.id)},
      secondaryStage: secondStage,
    };

    const station = {}
    const stationData = $stationList.result

    if (stationId && stationData.length > 0) {
      for (let i = 0; i < stationData.length; i++) {
        if (stationData[i].id === stationId) {

          station['station'] = stationData[i]
          data['station'] = station['station']
        }
      }
    }


    if (match.params.subId) {
      data.id = Number(match.params.subId)
    }

    setLoading(true);
    createSectordApi(data)
        .then(response => {
          if (response.status === 200) {
            setLoading(false);
            setEndPoint('');
            setLength('');
            setName('');
            setStartPoint('');
            setSecondStage(false)
            setStationId(undefined)

            notiPopup(
                'success',
                `Перегон ${match.params.id ? 'редактирован' : 'добавлен'}`
            )

            if (match.params.subId) {
              if (lastLocation) {
                history.push({
                  pathname: lastLocation.pathname,
                  search: lastLocation.search
                })
              } else {
                history.push(`/sections/${match.params.id}/sub`)
              }
            }
          }
        })
        .catch(error => {
          setLoading(false);
          notiPopup(
              'error',
              `Ошибка при ${match.params.id ? 'редактировании' : 'добавлении'}, попробуйте позже`
          )
        })
  };

  useEffect(() => {
    if (match.params.id) {
      getSectorInfo(match.params.id)
          .then(response => {
            if (response.status === 200) {
              setParentName(response.data.name);
            }
          })
          .catch(error => console.error(error.response))
    }
  }, [match.params.id]);

  useEffect(() => {
    if (match.params.subId) {
      getSectorInfo(match.params.subId)
          .then(response => {
            if (response.status === 200) {
              const data = response.data;
              setUploadFile(data.uploads.map(item => Number(item)))
              setParentName(data.parent.name);
              setEndPoint(data.countdownPoint);
              setLength(data.length);
              setName(data.name);
              setStartPoint(data.startPoint);
              setMaxLengthSector(data.parent.length);
              setStationId(data.station.id)
              setSecondStage(data.secondaryStage)
            }
          })
          .catch(error => {
            console.error(error.response)
          })
    }

  }, [match.params.subId]);

  useEffect(() => {
    if (id) {
      stationListMount(id)
    }
  }, [id])


  return (
      <Container>
        <FormSection>
          <GridBasic perColumn={2} columnGap={24} rowGap={40}>
            <GridItem>
              <InputSystem
                  disabled={loading}
                  value={name}
                  change={setName}
                  type='text'
                  placeholder='Введите название перегона'
                  label='Название'
              />
            </GridItem>
            <GridItem>
              <InputSystem
                  disabled={loading}
                  value={length}
                  change={setLength}
                  type='text'
                  placeholder='Длина перегона'
                  label='Длина'
              />
            </GridItem>
            <GridItem>
              <InputSystem
                  disabled={true}
                  value={parentName}
                  change={setParentName}
                  type='text'
                  placeholder='введите направление'
                  label='Направление'
              />
            </GridItem>
            <GridItem>
              <UploadFile
                  disabled={loading}
                  change={setUploadFile}
                  value={uploadFile}
                  type='text'
                  placeholder='Введите широту и долготу'
                  label='Загрузка файла'
              />
            </GridItem>
            <GridItem>
              <InputSystem
                  max={maxLengthSector}
                  disabled={loading}
                  change={setStartPoint}
                  value={startPoint}
                  type='sector'
                  placeholder='Введите точку начала стыковки'
                  label='точка начала'
              />
            </GridItem>
            <GridItem>
              <InputSystem
                  max={999}
                  disabled={loading}
                  value={endPoint}
                  change={setEndPoint}
                  type='sector'
                  placeholder='Введите точку отсчета (в метрах)'
                  label='точка отсчета'
              />
            </GridItem>
            <GridItem>
              <SelectSystem
                  disabled={$stationList.loading}
                  placeholder='Выберите станцию'
                  options={$stationList.filtered}
                  label='Станция'
                  value={stationId}
                  change={setStationId}
                  style={{padding: 0}}
                  allowClear={true}
              />
            </GridItem>
            <GridItem>
              <div style={{display:'flex', alignItems: 'center', height: 48, marginTop: 'auto'}}>
                <Checkbox onChange={(e) => setSecondStage(e.target.checked)} checked={secondStage}>
                  Станционный путь
                </Checkbox>
              </div>
            </GridItem>
          </GridBasic>
          <ButtonCenter>
            <Button onClick={() => history.push('/')} type='link'>Отмена</Button>
            <Button
                loading={loading}
                disabled={
                  !name
                  || !length
                }
                onClick={() => createSector()}>Добавить</Button>
          </ButtonCenter>
        </FormSection>
      </Container>
  )
};

export const SectorSubFormBlock = withRouter(SectorSubFormGridItemView);
