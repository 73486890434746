import React, { useEffect, useState } from 'react';
import { Block, Button, ButtonCenter, Group } from "../../../ui/atoms";
// import {InputSystem} from "../../../ui/molecules";
import { ListForm } from "../../categories-list-add/atoms";
import { SelectSystem } from "../../../ui/molecules/select";
import {
    addSectorForOrg,
    // getListSectors,
    getListSectorsPlainlist
} from "../api";
import { withRouter } from "react-router-dom";
import { notification } from "antd";


const AddSectorOrganizationView = (props) => {
    const {
        match
    } = props;

    //data
    const [search, setSearch] = useState(undefined);
    const [sectorId, setSectorId] = useState(undefined);
    const [listOptions, setListOptions] = useState([]);

    //status
    const [loading, setLoading] = useState(false);


    const addChange = () => {
        const data = {
            id: match.params.id,
            sectorId: sectorId
        };
        setLoading(true);
        addSectorForOrg(data)
            .then(response => {
                if (response.status === 200) {
                    setLoading(false);
                    setSearch(undefined);
                    setSectorId(undefined)
                    notification['success']({
                        message: 'Успешно',
                        description: 'Участок успешно добавлен'
                    });
                }
            })
            .catch(error => {
                setLoading(false);
                console.error(error.response);
                notification['error']({
                    message: 'Ошибка',
                    description: 'Ошибка, попробуйте позже'
                });
            })
    };


    useEffect(() => {
        const params = {
            "search.value": search
        };
        getListSectorsPlainlist(params)
            .then(response => {
                if (response.status === 200) {
                    // console.log(response.data);
                    const data = response.data.data;
                    let options = [];
                    for (let i = 0; i < data.length; i++) {
                        options.push({
                            key: data[i].id,
                            value: data[i].id,
                            label: data[i].name
                        })
                    }
                    setListOptions(options);
                }
            })
            .catch(error => {
                console.error(error.response)
            })
    }, [search]);

    return (
        <ListForm>
            <Group>
                <Block span='100%' style={{ marginTop: 0 }}>
                    <SelectSystem
                        showSearch={true}
                        onSearch={setSearch}
                        disabled={loading}
                        value={sectorId}
                        change={setSectorId}
                        options={listOptions}
                        label='Список'
                        placeholder='Выберите участок'
                    />
                </Block>
            </Group>
            <ButtonCenter>
                <Button type='link'>Отмена</Button>
                <Button
                    loading={loading}
                    disabled={
                        !sectorId
                    }
                    onClick={() => addChange()}>Добавить</Button>
            </ButtonCenter>
        </ListForm>
    )
};

export const AddSectorOrganization = withRouter(AddSectorOrganizationView);
