export const ROLES_VALUE = {
    SUPER_ADMIN: 'SuperAdmin',
    MANAGER: 'Менеджер',
    ADMIN: 'Администратор',
    EMPLOYEE: 'Сотрудник',
    USER: 'Начальник дистанции',
    BOSS: 'Руководство'
} 

export const ROLES_DATA = [
    {
        value: 'SUPER_ADMIN',
        label: ROLES_VALUE.SUPER_ADMIN
    },
    {
        value: 'ADMIN',
        label: ROLES_VALUE.ADMIN
    },
    {
        value: 'MANAGER',
        label: ROLES_VALUE.MANAGER
    },
    {
        value: 'EMPLOYEE',
        label: ROLES_VALUE.EMPLOYEE
    },
    {
        value: 'USER',
        label: ROLES_VALUE.USER
    },
    {
        value: 'BOSS',
        label: ROLES_VALUE.BOSS
    }
];
