import {combine, createStore} from "effector";
import {fetchSectorList} from "./effects";

const $sectorList = createStore({loading: false, data: [], error: false})
    .on(fetchSectorList.pending, (state, pending) => {
      return {
        ...state,
        loading: pending
      }
    })
    .on(fetchSectorList.finally, (state, res) => {
      return {
        ...state,
        error: false,
        data: res.result.data.data
      }
    })
    .on(fetchSectorList.fail, (state, error) => {
      return {
        ...state,
        data: [],
        error: error.response.error
      }
    })

export const $sector = combine({
  $sectorList
})