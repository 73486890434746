import React, { Fragment } from 'react';
import { BodyHead } from "../../../components/body-head";
import { AddUserForm } from "../organisms";
import { withRouter } from "react-router-dom";

const UsersAddView = (props) => {
    const {
        match
    } = props;

    return (
        <Fragment>
            <BodyHead back='users' title={`${match.params.id ? 'Редактировать' : 'Добавить'} пользователя`} />
            <AddUserForm />
        </Fragment>
    )
};

export const UsersAdd = withRouter(UsersAddView);
