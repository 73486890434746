import React, {Fragment, useEffect, useCallback} from "react";
import {Link} from "react-router-dom";
import {HeaderCenter, HeaderLeft, HeaderRight, HeaderSection, Overlay,} from "./atoms";
import {BlueLogoSvg, MainButtonSvg} from "../../media";
import {UserProfile} from "./organisms";
import {MainButton} from "../../features/sign-in/atoms";
import {menuVisibleStatus, $appSettings} from "../../models/app-model";
import {useStore} from "effector-react";
import {useHistory} from "react-router-dom";

export const Header = () => {
    const {$menuVisible} = useStore($appSettings)
    const {location} = useHistory()
    const windowResize = () => {
        if (window.innerWidth <= 1280) {
            menuVisibleStatus(false)
        } else {
            menuVisibleStatus(true)
        }
    }

    const bodyOverflow = useCallback(() => {
        const body = document.body
        if (window.innerWidth <= 1200) {
            if ($menuVisible) {
                body.style.overflow = 'hidden'
            } else {
                body.style.overflow = 'visible'
            }
        } else {
            body.style.overflow = 'visible'
        }
    }, [$menuVisible])

    useEffect(() => {
        bodyOverflow()
    }, [bodyOverflow])

    useEffect(() => {
        window.addEventListener('resize', (e) => {
            // if (window.innerWidth <= 1200) {
            //     if ($menuVisible) {
            //         menuVisibleStatus(true)
            //     } else {
            //         menuVisibleStatus(false)
            //     }
            // } else {
            //     menuVisibleStatus(true)
            // }
            windowResize()
        })

    }, [$menuVisible])


    useEffect(() => {
        if (window.innerWidth <= 1280) {
            windowResize()
        }
    }, [location])


    return (
        <Fragment>
            <Overlay visible={$menuVisible} onClick={() => menuVisibleStatus(false)}/>
            <HeaderSection>
                <HeaderLeft>
                    <MainButton onClick={() => menuVisibleStatus(!$menuVisible)}>
                        <MainButtonSvg/>
                    </MainButton>
                    <Link to="/">
                        <BlueLogoSvg/>
                    </Link>
                </HeaderLeft>
                <HeaderCenter>
                    {/*<InputSystem icon={<SearchSvg/>} placeholder="Поиск..."/>*/}
                </HeaderCenter>
                <HeaderRight>
                    {/*<Notification/>*/}
                    <UserProfile/>
                </HeaderRight>
            </HeaderSection>
        </Fragment>
    );
};
