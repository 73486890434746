import React, { useEffect, useState } from 'react';
import { AvatarUpload } from "../../../components/avatar-upload";
import { GridItem, Button, ButtonCenter, Group, Container, FormSection } from "../../../ui/atoms";
import { InputSystem } from "../../../ui/molecules";
import { SelectSystem } from "../../../ui/molecules/select";
import { EditUserApi, getEditUserInfo, getPositionsList } from "../api";
import { withRouter } from "react-router-dom";
import { ROLES_DATA } from "../helpers";
import { GridBasic } from '../../../ui/atoms/section';
import { notiPopup } from '../../../components/notification';
import { useLastLocation } from 'react-router-last-location';

const EditUserFormView = (props) => {
    const {
        match,
        history
    } = props;

    const lastLocation = useLastLocation()

    const [fileId, setFileId] = useState(false);
    const [name, setName] = useState('');
    const [sName, setSName] = useState('');
    const [nick, setNick] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [gender, setGender] = useState(undefined);
    const [role, setRole] = useState(undefined);
    const [position, setPosition] = useState(undefined);
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');

    //options
    const [positionsOptions, setPositionsOptions] = useState([]);

    //status
    const [loading, setLoading] = useState(false);
    const [disabledLogin, setDisabledLogin] = useState(false);


    const clear = () => {
        setAddress('');
        setGender(undefined);
        setFileId(false);
        setName('');
        setPassword('');
        setRepeatPassword('');
        setPhone('');
        setSName('');
        setNick('');
        setRole(undefined);
        setPosition(undefined);
        if (match.params.id) {
            if (lastLocation) {
                history.push({
                    pathname: lastLocation.pathname,
                    search: lastLocation.search
                })
            } else {
                history.push(`/users`)
            }
        }
    };
    const CreateUser = () => {
        const data = {
            address: address,
            city: "string",
            gender: gender,
            image: fileId,
            name: name,
            password: password,
            phone: phone,
            roles: [
                role
            ],
            sname: sName,
            username: nick,
            position: {
                id: position
            }
        };
        setLoading(true);
        EditUserApi(data)
            .then(response => {
                if (response.status === 200) {
                    notiPopup(
                        'success',
                        'Полльзователь редактирован.'
                    )
                    setLoading(false);
                    clear();
                }
            })
            .catch(error => {
                notiPopup(
                    'error',
                    'Ошибка при редактировании, попробуйте позже.'
                )
                setLoading(false);
            });
    };

    useEffect(() => {
        getPositionsList()
            .then(response => {
                if (response.status === 200) {
                    let data = [];
                    for (let i = 0; i < response.data.length; i++) {
                        data.push({
                            value: response.data[i].id,
                            label: response.data[i].title
                        })
                    }
                    setPositionsOptions(data)
                }
            })
            .catch(error => {
                console.log(error.response)
            })
    }, []);

    useEffect(() => {
        if (match.params.id) {
            getEditUserInfo(match.params.id)
                .then(response => {
                    if (response.status === 200) {
                        const data = response.data;
                        setFileId(data.image);
                        setPassword(undefined);
                        setRepeatPassword(undefined);
                        setName(data.name);
                        setSName(data.sname);
                        setNick(data.username);
                        setGender(Number(data.gender));
                        setRole(data.roles[0]);
                        setPosition(data.position && data.position.id ? data.position.id : undefined);
                        setPhone(data.phone);
                        setAddress(data.address);
                        setDisabledLogin(true);
                    }
                })
                .catch(error => {
                    console.error(error.response)
                })
        }
    }, [match]);

    return (
        <Container>
            <FormSection>
                <AvatarUpload file={fileId} setFileId={setFileId} style={{ marginBottom: 50 }} />
                <GridBasic perColumn={2} rowGap={40} columnGap={24} >
                    <GridItem>
                        <InputSystem
                            disabled={loading}
                            value={name}
                            change={setName}
                            type='text'
                            placeholder='Введите имя'
                            label='Имя'
                        />
                    </GridItem>
                    <GridItem>
                        <InputSystem
                            disabled={loading}
                            value={sName}
                            change={setSName}
                            type='text'
                            placeholder='Введите фамилию'
                            label='Фамилия'
                        />
                    </GridItem>
                    <GridItem>
                        <InputSystem
                            disabled={loading || disabledLogin}
                            value={nick}
                            change={setNick}
                            type='text'
                            placeholder='Введите логин пользователя для авторизации'
                            label='Логин пользователя'
                        />
                    </GridItem>
                    {
                        !match.params.id &&
                        <GridItem>
                            <InputSystem
                                disabled={loading}
                                noUpperCase
                                value={password}
                                change={setPassword}
                                type='password'
                                placeholder='Создайте пароль'
                                label='Пароль'
                            />
                        </GridItem>
                    }
                    {
                        !match.params.id &&
                        <GridItem>
                            <InputSystem
                                disabled={loading}
                                noUpperCase
                                value={repeatPassword}
                                change={setRepeatPassword}
                                type='password'
                                placeholder='Повторите пароль'
                                label='Подтвердите пароль'
                            />
                        </GridItem>
                    }

                    <GridItem>
                        <SelectSystem
                            disabled={loading}
                            change={setRole}
                            value={role}
                            label='Роль'
                            placeholder='Выберите роль для пользователя'
                            options={ROLES_DATA}
                        />
                    </GridItem>
                    <GridItem>
                        <SelectSystem
                            disabled={loading}
                            value={gender}
                            change={setGender}
                            label='Пол'
                            placeholder='Выберите пол'
                            options={[{ value: 1, label: 'Муж' }, { value: 2, label: 'Жен' }]}
                        />
                    </GridItem>
                    <GridItem>
                        <SelectSystem
                            disabled={loading}
                            change={setPosition}
                            label='Должность'
                            placeholder='В какой должности работает пользователь?'
                            options={positionsOptions}
                            value={position}
                        />
                    </GridItem>
                    <GridItem>
                        <InputSystem
                            disabled={loading}
                            value={phone}
                            change={setPhone}
                            type='phone'
                            placeholder='Номер телефона'
                            label='Введите номер телефона'
                        />
                    </GridItem>
                    <GridItem>
                        <InputSystem
                            disabled={loading}
                            value={address}
                            change={setAddress}
                            type='text'
                            placeholder='Адрес'
                            label='Введите адрес'
                        />
                    </GridItem>
                </GridBasic>
                <ButtonCenter>
                    <Button type='link'>Отмена</Button>
                    <Button
                        loading={loading}
                        disabled={
                            !name
                            || !nick
                            // || (password !== repeatPassword)
                            // || !password
                            || !gender
                            || !role
                            || !phone
                            || !address
                            || !position
                        }
                        onClick={() => CreateUser()}>Добавить</Button>
                </ButtonCenter>
            </FormSection>
        </Container>
    )
};


export const EditUserForm = withRouter(EditUserFormView);
