import React, { useState, useEffect } from 'react';
import Popover from "react-tiny-popover";
import { PopConfirmContent } from "../../../components/pop-confirm/atoms";
import { MoreHorizontalSvg, CheckMarkSvg, CloseSvg } from "../../../media";
import { withRouter } from "react-router-dom";
import { notiPopup } from '../../../components/notification';
import { REQUEST_STATUS } from '../hook';
import { changeRequestStatus } from '../api';


const PopoverItemView = (props) => {
    const {
        id,
        updateList,
    } = props;

    
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    
    const requestStatusChange = (status) => {
        const data = {
            id,
            status
        }
        changeRequestStatus(data)
        .then(res => {
            if (res.status === 200){
                updateList()
                notiPopup(
                    'success',
                    `Запрос ${status === REQUEST_STATUS.SATISFIED ? 'одобрен' : 'отказен'}`
                )
            }
        })
        .catch(err => {
            notiPopup(
                'error',
                'Ошибка при удалении, попробуйте позже'
            )
        })
    }

    return (
        <Popover
            isOpen={isPopoverOpen}
            onClickOutside={() => setIsPopoverOpen(false)}
            position={'bottom'} // preferred position
            content={(
                <PopConfirmContent>
                    <span style={{ color: '#00E096' }} onClick={() => requestStatusChange(REQUEST_STATUS.SATISFIED)}>
                                <CheckMarkSvg /> Одобрить
                            </span>
                            <span style={{ color: 'red' }} onClick={() => requestStatusChange(REQUEST_STATUS.DECLINED)}>
                                <CloseSvg /> Отказать
                            </span>
                </PopConfirmContent>
            )}
        >
            <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                <MoreHorizontalSvg />
            </div>
        </Popover>
    )
};

export const PopoverItem = withRouter(PopoverItemView);
