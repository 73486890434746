import React, {useState} from 'react';
import {Button, LinkCustom, Section} from "../../ui/atoms";
import {ButtonGroup, FormBlock, SignInBlock} from "./atoms";
import {withTheme} from "styled-components";
import {LogoSvg, PasswordSvg, Person} from "../../media";
import {InputSystem} from "../../ui/molecules";
import {getToken} from "./api";
import Cookies from 'js-cookie';
import {openNotificationWithIcon} from "../../helpers";
import {withRouter} from "react-router-dom";
import {USER_ROLES} from "../../constants/user";
import {getSignedUserInfo} from "../../api/user-api";
import {notiPopup} from "../../components/notification";


const SignInView = (props) => {
    const {history} = props
    //status
    const [loading, setLoading] = useState(false);
    //data
    const [login, setLogin] = useState(false);
    const [password, setPassword] = useState(false);

    const authUser = (e) => {
        e.preventDefault();
        Cookies.remove('token');
        let data = {
            login: login,
            password: password
        };
        setLoading(true);
        getToken(data)
            .then(response => {
                if (response.status === 200) {
                    Cookies.set('token', response.data);
                    getSignedUserInfo()
                        .then(res => {
                            if (res.status === 200) {
                                setLoading(false)
                                if (res.data.roles[0] === USER_ROLES.ADMIN || res.data.roles[0] === USER_ROLES.SUPER_ADMIN) {
                                    props.history.push('/');
                                    notiPopup(
                                        'success',
                                        'Вы успешно авторизовались.'
                                    );
                                    history.push('/');
                                } else {
                                    Cookies.remove('token');
                                    notiPopup(
                                        'error',
                                        'У вас нет прав доступа к данной операции'
                                    );
                                }
                            }
                        }).catch(err => {
                        console.log(err);
                        setLoading(false)
                    })
                }
            })
            .catch(error => {
                setLoading(false);
                if (error.status === 401) {
                    openNotificationWithIcon('error', 'Ошибка', 'Неверный логин или пароль.');
                } else {
                    openNotificationWithIcon('error', 'Ошибка', 'Неверный логин или пароль.');
                }
            })
    };


    return (
        <Section>
            <SignInBlock onSubmit={(e) => authUser(e)} background={props.theme.main.brandColor}>
                <LogoSvg/>
                <FormBlock>
                    <InputSystem
                        disabled={loading}
                        noUpperCase
                        change={setLogin}
                        icon={<Person/>}
                        placeholder='Введите логин'
                    />
                    <InputSystem
                        disabled={loading}
                        noUpperCase
                        change={setPassword}
                        type='password'
                        placeholder='Введите пароль'
                    />
                    <ButtonGroup style={{justifyContent: 'flex-end'}}>
                        <Button htmlType="submit" loading={loading}>Войти</Button>
                    </ButtonGroup>
                </FormBlock>
            </SignInBlock>
        </Section>
    )
};

export const SignIn = withTheme(withRouter(SignInView));
