import {useState, useCallback, useEffect} from "react"
import {userListMount} from "../../models/user-model"
import {useParams, useHistory} from "react-router-dom"
import {getUserPermission, addUserPermission} from "../../api/organization-api"
import {numberFormat} from "../../helpers"
import {useLastLocation} from "react-router-last-location"
import {notiPopup} from "../../components/notification"

export const useUserPermission = () => {

  const {permissionId, id} = useParams()
  const {push} = useHistory()
  const lastLocation = useLastLocation()

  const [name, setName] = useState('')
  const [userSearch, setUserSearch] = useState('')
  const [check, setCheck] = useState(0)
  const [loading, setLoading] = useState(false)
  const [permissionLoading, setPermissionLoading] = useState(false)
  const [variants, setVariants] = useState([
    {
      start: 0,
      end: 0,
      sectorInfo: {},
      subSectorInfo: {},
    },
  ]);
  const [errors, setError] = useState({})

  const getPermissions = useCallback(async (id) => {
    const tmp = []
    setLoading(true)
    setPermissionLoading(true)
    try {
      const res = await getUserPermission(id)
      const data = res.data
      const permissions = data.permissions
      for (let i = 0; i < permissions.length; i++) {
        tmp.push({
          start: permissions[i].allowedStart,
          end: permissions[i].allowedEnd,
          sectorInfo: permissions[i].sector,
          subSectorInfo: permissions[i].subSector
        })
      }
      setVariants(tmp)
      setName(data.user.username)
      setUserSearch(data.user.username)
      setCheck(Math.random())
      setLoading(false)
      setPermissionLoading(false)
    } catch (e) {
      console.log(e);
      setLoading(false)
      setPermissionLoading(false)
    }
  }, [])

  const getUserList = useCallback(() => {

    if (userSearch.length > 2) {
      const params = {
        'search.value': userSearch,
      }
      userListMount(params)
    }
  }, [userSearch])


  const change = useCallback((e, idx, type) => {
    const data = [...variants]
    data[idx][type] = e
    setVariants(data)
    setCheck(Math.random())
  }, [variants])

  const addItem = (idx) => {
    const newItem = {
      start: 0,
      end: 0,
      sectorInfo: {},
      subSectorInfo: {},
    }

    const data = variants
    const newData = [...data.slice(0, idx + 1), newItem, ...data.slice(idx + 1)]
    setVariants(newData)
  };

  const deleteItem = (idx) => {
    const data = variants
    const newData = data.filter((item, i) => i !== idx)
    setVariants(newData)

    setCheck(Math.random())
  }

  const getLength = (data, start) => {
    if (start) {
      return data.startPoint * 1000 + data.countdownPoint
    } else {
      return data.startPoint * 1000 + data.countdownPoint + data.length
    }
  }

  const validate = useCallback(() => {
    const requiredText = 'Поле обязательно для заполнения'
    let allow = true
    const data = variants
    const error = {...errors}

    for (let i = 0; i < data.length; i++) {
      error[i] = error[i] ? {...error[i]} : {}

      if (Object.values(data[i].sectorInfo).length === 0) {
        error[i]['sector'] = requiredText
        allow = false
      } else {
        delete error[i]['sector']
        allow = true
      }


      if (Object.values(data[i].subSectorInfo).length === 0) {
        error[i]['subSector'] = requiredText
        allow = false
      } else {
        delete error[i]['subSector']
        allow = true
      }


      if (data[i].start.toString().length === 0) {
        errors[i]['start'] = requiredText
        allow = false
      } else {
        delete error[i]['start']
        allow = true
      }


      if (data[i].end.toString().length === 0) {
        errors[i]['end'] = requiredText
        allow = false
      } else {
        delete error[i]['end']
        allow = true
      }

      if (Object.values(data[i].subSectorInfo).length > 0) {
        if (data[i]['start'].length > 0 && (Number(data[i]['start']) < getLength(data[i]["subSectorInfo"], true)
            || Number(data[i]['start']) > getLength(data[i]["subSectorInfo"], false))) {
          error[i]['start'] = `Введенное значение не должно быть ниже ${numberFormat(getLength(data[i]["subSectorInfo"], true))} м и выше ${numberFormat(getLength(data[i]["subSectorInfo"]))} м`
          allow = false
        }
        if (data[i]['end'].length > 0 && (Number(data[i]['end']) < getLength(data[i]["subSectorInfo"], true)
            || Number(data[i]['end']) > getLength(data[i]["subSectorInfo"], false))) {
          data[i]["errors"]['end'] = `Введенное значение не должно быть ниже ${numberFormat(getLength(data[i]["subSectorInfo"], true))} м и выше ${numberFormat(getLength(data[i]["subSectorInfo"]))} м`
          allow = false
        }
      } else {
        allow = false
      }

      if (data.length === i + 1) {
        setError(error)
      }
    }

    return allow
  }, [variants, errors])

  const saveUserPermission = useCallback(async (data, save) => {
    setLoading(true)
    try {
      const res = await addUserPermission(data)
      if (res.status === 200) {
        if (save) {
          console.log(save);
          if (id) {
            if (lastLocation) {
              push({
                pathname: lastLocation.pathname,
                search: lastLocation.search
              })
            } else {
              push(`/organization/${id}/user`)
            }
          }

          setName('')
          setVariants([
            {
              start: 0,
              end: 0,
              sectorInfo: {},
              subSectorInfo: {},
            },
          ])
        }

        if (!save && permissionId === undefined && id) {
          push(`/organization/${id}/user/${res.data.id}/edit`)
        }

        notiPopup(
            'success',
            'Специалист прикреплен'
        )
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
      notiPopup(
          'error',
          'Ошибка, попробуйте позже'
      )
    }
  }, [id, lastLocation, push])

  const savePermission = useCallback((save) => {
    if (validate()) {
      const data = variants.map((item) => ({
        allowedEnd: Number(item.end),
        allowedStart: Number(item.start),
        sector: item.sectorInfo,
        subSector: item.subSectorInfo
      }))

      const payload = {
        id: id,
        organizationId: id,
        permissions: data,
        user: {
          username: name
        },
      }

      if (permissionId) {
        payload['id'] = Number(permissionId)
      }
      saveUserPermission(payload, save)
    }
  }, [id, name, validate, permissionId, saveUserPermission, variants])

  const addChangeAndContinue = useCallback(() => {
    savePermission(false)
  }, [savePermission])

  const addChange = useCallback(() => {
    savePermission(true)
  }, [savePermission])

  useEffect(() => {
    getUserList()
  }, [getUserList])

  useEffect(() => {
    if (permissionId) {
      getPermissions(permissionId)
    }
  }, [permissionId, getPermissions])


  return {
    name,
    setName,
    setUserSearch,
    errors,
    variants,
    change,
    addItem,
    deleteItem,
    loading,
    addChange,
    check,
    permissionLoading,
    addChangeAndContinue
  }
}