import { combine, createStore } from "effector";
import { fetchAllOrgUserList, fetchSignedUserInfo, fetchUserList } from "./effect";
import { deleteUserRole } from "./event";

const $signedUserInfo = createStore({ loading: false, data: {}, error: false })
    .on(fetchSignedUserInfo.pending, (state, pending) => {
        return {
            ...state,
            loading: pending
        }
    })
    .on(fetchSignedUserInfo.finally, (state, response) => {
        if (response.error) {
            return {
                ...state,
                error: response.error.response
            }
        } else {
            return {
                ...state,
                data: response.result.data
            }
        }
    })

const $userRole = createStore(null)
    .on(fetchSignedUserInfo.finally, (state, response) => {
        return response.result.data.roles ? response.result.data.roles.join('') : "ADMIN"
    })
    .on(deleteUserRole, (params) => {
        return null
    })

const $allOrgUserList = createStore({ loading: false, data: {}, error: false })
    .on(fetchAllOrgUserList.pending, (state, pending) => {
        return {
            ...state,
            loading: pending
        }
    })
    .on(fetchAllOrgUserList.finally, (state, response) => {
        if (response.error) {
            return {
                ...state,
                error: response.error.response
            }
        } else {
            return {
                ...state,
                error: false,
                data: response.result.data
            }
        }
    })

const $userList = createStore({ loading: false, results: {}, data: [], options: [],  error: false })
    .on(fetchUserList.pending, (state, pending) => ({ ...state, loading: pending }))
    .on(fetchUserList.fail, (state, error) => ({ ...state, error: error.response, data: [], results: {}, options: [] }))
    .on(fetchUserList.finally, (state, res) => ({ 
        ...state, 
        error: false, 
        data: res.result.data.data, 
        results: res.result.data,
        options: res.result.data.data.map(item => ({
            value: item.username,
            label: `${item.name} ${item.sname}`
        }))
    }))

export const $userModel = combine({
    $signedUserInfo,
    $userRole,
    $allOrgUserList,
    $userList
})