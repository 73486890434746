import React from 'react';
import Moment from 'react-moment';
import 'moment/locale/ru';
import {MoreButton, UserImage, UserSection, UserText} from "../atoms";
import {withRouter} from "react-router-dom";
import {ImageLoad} from "../../../components/download";
import {PopoverItem} from "../molecules";
import {ROLES_VALUE} from "../helpers";
import {BriefCaseSvg, PersonGroupLineSvg, PersonLineSvg, PhoneSvg, TimerSvg} from "../../../media/svg";

const UserView = (props) => {
    // const [ confirmStatus, setConfirmStatus ] = useState(false);
    const {
        data,
        active,
        updateData
    } = props;
    return (
          <UserSection active={active}>

              <UserImage active={active}>
                  {
                      data.image && <ImageLoad id={data.image} show={true}/>
                  }
              </UserImage>
              <UserText name={true} to={`/users/${data.username}`} active={active}>
                  <div>
                      <div style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          maxWidth: 130,
                          minWidth: 130
                      }}
                      >
                          {`${data.name} ${data.sname}`}
                      </div>
                      <span>{data.position && data.position.title ? data.position.title : 'Не указан'}</span>
                  </div>

              </UserText>
              <UserText to={`/users/${data.username}`} active={active}>
                  {
                      active &&
                      <PersonLineSvg/>
                  }
                  <div>
                      <div>
                          {data.username}
                      </div>
                      <span>Логин</span>
                  </div>

              </UserText>
              <UserText to={`/users/${data.username}`} active={active}>
                  {
                      active &&
                      <PersonGroupLineSvg/>
                  }
                  <div>
                      <div>
                          {
                              data.roles && data.roles.map((role, i) => ROLES_VALUE[role] + (i === (data.roles.length - 1) ? '' : ' ,'))
                          }
                      </div>
                      <span>Роль</span>
                  </div>

              </UserText>
              <UserText to={`/users/${data.username}`} active={active}>
                  {
                      active &&
                      <BriefCaseSvg/>
                  }
                  <div>
                      <div>
                          {data.organizations.length
                                ? data.organizations.map((item, i) => item.name + (i === (data.organizations.length - 1) ? '' : ' ,'))
                                : 'не прикреплен'
                          }
                      </div>
                      <span>Организация</span>
                  </div>

              </UserText>
              <UserText to={`/users/${data.username}`} active={active}>
                  {
                      active &&
                      <TimerSvg/>
                  }
                  <div>
                      <div>
                          {data.phone}
                      </div>
                      <span>Телефон</span>
                  </div>

              </UserText>
              <UserText to={`/users/${data.username}`} active={active}>
                  {
                      active &&
                      <PhoneSvg/>
                  }
                  <div style={{minWidth: 150}}>
                      <div>
                          {
                              data.lastVisit !== null
                                    ? <Moment fromNow>{data.lastVisit}</Moment>
                                    : 'неактивен'
                          }

                      </div>
                      <span>Последняя активность</span>
                  </div>

              </UserText>
              <MoreButton active={active}>
                  <PopoverItem userStatus={data.status} updateData={updateData} userInfo={data}/>
              </MoreButton>
          </UserSection>
    )
};
export const User = withRouter(UserView);
