import React, {useState} from 'react';
import {removeCategory} from "../api";
import Popover from "react-tiny-popover";
// import {PopConfirm} from "../../../components/pop-confirm";
import {PopConfirmContent} from "../../../components/pop-confirm/atoms";
import {EditSvg, MoreHorizontalSvg, TrashSvg} from "../../../media";
import {withRouter} from "react-router-dom";
import {deleteConfirm} from "../../../ui/molecules";
import {notiPopup} from '../../../components/notification';


const PopoverItemView = (props) => {
  const {
    id,
    history,
    updateList
  } = props;

  // console.log('dddd', id)

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);


  const removeSector = () => {
    removeCategory(id)
        .then(response => {
          if (response.status === 200) {
            notiPopup(
                'success',
                'Направления удалена'
            )
            updateList();
          }
        })
        .catch(error => {
          console.log(error);
          notiPopup(
              'error',
              'Ошибка при удалении, попробуйте позже'
          )
        })
  };

  return (
      <Popover
          isOpen={isPopoverOpen}
          onClickOutside={() => setIsPopoverOpen(false)}
          position={'bottom'} // preferred position
          content={(
              <PopConfirmContent>
                <span onClick={() => history.push(`/sections/${id}/edit`)}><EditSvg/> Редактировать</span>
                <span className='delete'
                      onClick={() => deleteConfirm(removeSector)}
                >
                        <TrashSvg/> Удалить
                    </span>
              </PopConfirmContent>
          )}
      >
        <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
          <MoreHorizontalSvg/>
        </div>
      </Popover>
  )
};

export const PopoverItem = withRouter(PopoverItemView);
