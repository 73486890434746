import {createEvent} from "effector";
import {
    fetchOrganizationList,
    fetchOrganizationSectorsList,
    fetchSectorInfo,
    fetchContextInfo,
    fetchOrgList
} from "./effects";


export const organizationListMount = createEvent();
export const getOrganizationSectorsList = createEvent();
export const mountSubSectorsToParent = createEvent();
export const contextInfo = createEvent();
export const getSectorsFromOrganization = createEvent();
export const userOrganizations = createEvent();
export const createSectorLength = createEvent();
export const previousEvent = createEvent();
export const nextEvent = createEvent();
export const orgListMount = createEvent()




organizationListMount.watch(fetchOrganizationList);
getOrganizationSectorsList.watch(fetchOrganizationSectorsList);
mountSubSectorsToParent.watch(fetchSectorInfo);
contextInfo.watch(fetchContextInfo);
orgListMount.watch(fetchOrgList)

