import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useStore } from "effector-react";
import { $isDataPending, categoriesPageMounted, categoryPageSizeChange } from "../model";
import { AntTable, Container, } from "../../../ui/atoms";
import { PopoverItem } from "../molecules";
import { withRouter } from 'react-router-dom'


const CategoriesListView = (props) => {
    const { search, location, history, sortValue } = props

    const isDataPending = useStore($isDataPending);

    const [start, setStart] = useState(0);
    const [pageSize, setPageSize] = useState(isDataPending.$categorySettings.category.pageSize ? isDataPending.$categorySettings.category.pageSize : 20);
    const [page, setPage] = useState(1);

    const [data, setData] = useState([]);

    useEffect(() => {
        const paramsString = location.search;
        const searchParams = new URLSearchParams(paramsString);
        if (searchParams.has('page')) {
            const _page = Number(searchParams.get('page'))
            setPage(_page)
            setStart(((_page - 1) * pageSize))

        } else {
            setStart(0)
            setPage(1)
        }
    }, [location.search, pageSize])

    function changePagination(page) {

        if (page !== 1) {
            history.push({
                pathname: '/categories',
                search: `page=${page}`
            })
        } else {
            history.push('/categories')
        }

    }

    function changeShowSize(current, size) {
        setPageSize(size)
        const payload = { pageSize: size }
        categoryPageSizeChange(payload)
    }


    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: 70,
            render: id => (<Link to={`/categories/${id}`}>{id}</Link>)
            // Cell: props => <div>{props.original.id}</div>
        },
        {
            title: 'Название',
            dataIndex: 'name',
            render: props => (<Link to={`/categories/${props.id}`}>{props.name}</Link>)
            // Cell: props => <div>{props.original.name}</div>
        },
        {
            title: '',
            dataIndex: 'popover',
            key: 'popover',
            width: 70,
            render: id => (<PopoverItem updateList={updateList} id={id} />)
        }
    ];

    useEffect(() => {
        let data = [];
        if (isDataPending && isDataPending.$categories && isDataPending.$categories.data) {
            for (let i = 0; i < isDataPending.$categories.data.length; i++) {
                data.push({
                    id: isDataPending.$categories.data[i].id,
                    key: isDataPending.$categories.data[i].id,
                    popover: isDataPending.$categories.data[i].id,
                    name: { name: isDataPending.$categories.data[i].name, id: isDataPending.$categories.data[i].id }
                })
            }
        }
        setData(data);
        // console.log(data)
    }, [isDataPending]);


    useEffect(() => {
        if (search !== null) {
            const params = {
                length: pageSize,
                start: start,
                "search.value": search,
            };
            categoriesPageMounted(params)
        }
    }, [start, pageSize, search]);

    function updateList() {
        const params = {
            length: pageSize,
            start: start,
            "search.value": search,
        };
        categoriesPageMounted(params)
    }


    return (
        <Container>
            <AntTable
                columns={columns}
                dataSource={data}
                pagination={
                    {
                        pageSize: pageSize,
                        pageSizeOptions: ['20', '30', '40', '50', '60', '70', '80', '90', '100'],
                        total: isDataPending.$categories.recordsTotal,
                        onChange: changePagination,
                        onShowSizeChange: changeShowSize,
                        current: page,
                        showSizeChanger: true,
                        showLessItems: true
                    }
                }
            />
        </Container>
    )
};

export const CategoriesList = withRouter(CategoriesListView);
