import React, {Fragment} from 'react'
import {Container, FormSection, GridItem, Button, GridColumn, ButtonCenter} from '../../../ui/atoms'
import {useUserPermission} from '../../../hooks/organization'
import {SelectSystem} from '../../../ui/molecules/select'
import {useStore} from 'effector-react'
import {$userModel} from '../../../models/user-model'
import {VariantTitle} from '../../categories-list-add/atoms'
import {UserPermissionItem} from '../moleculas'
import {PermissionGrid} from '../atoms'

export const UserPermisson = () => {
  const {
    setName,
    name,
    setUserSearch,
    addItem,
    variants,
    deleteItem,
    change,
    errors,
    loading,
    addChange,
    permissionLoading,
    addChangeAndContinue
  } = useUserPermission()

  const {$userList} = useStore($userModel)

  return (
      <Container>
        <FormSection>
          <PermissionGrid perColumn={2} rowGap={40} columnGap={24}>
            <GridItem gridColumn='1/3'>
              <SelectSystem
                  showSearch={true}
                  change={setName}
                  type='text'
                  placeholder='Введите фамилию или имя'
                  label='Пользователь'
                  onSearch={setUserSearch}
                  value={name}
                  options={$userList.options}
                  loading={$userList.loading}
              />
            </GridItem>
          </PermissionGrid>
          {!permissionLoading && variants.length > 0 && variants.map((item, i) => (
              <Fragment key={i}>
                <GridColumn column={2} gap={25} style={{marginBottom: 20}}>
                  <VariantTitle>Административное деление № {i + 1}</VariantTitle>
                </GridColumn>
                <UserPermissionItem
                    idx={i}
                    change={(e, idx, type) => change(e, idx, type)}
                    error={errors[i] && errors[i]}
                    _sectorId={item.sectorInfo.id && item.sectorInfo.id}
                    _subSectorId={item.subSectorInfo.id && item.subSectorInfo.id}
                    start={item.start}
                    end={item.end}
                    addItem={() => addItem(i)}
                    deleteItem={deleteItem}
                    length={variants.length}
                />
              </Fragment>
          ))}
          <ButtonCenter>
            <Button type='link'>Отмена</Button>
            <Button
                loading={loading}
                disabled={
                  !name
                }
                onClick={() => addChange()}>
              Сохранить
            </Button>
            <Button
                loading={loading}
                disabled={
                  !name
                }
                type='link'
                onClick={() => addChangeAndContinue()}>
              Сохранить и продолжить
            </Button>
          </ButtonCenter>
        </FormSection>
      </Container>
  )
}