import React, { useEffect, useState, useCallback } from 'react';
import { useStore } from "effector-react";
import { Pagination, Row, Col, Empty } from 'antd';
import { $isDataPending, usersPageMounted, userPageSizeSettingsChange } from "../model";
import { User } from "./index";
import { useHistory } from "react-router-dom";
import { Container } from '../../../ui/atoms';

export const UserList = (props) => {
    const {
        active,
        search,
        sortValue
    } = props;
    const { location, push } = useHistory()

    const { $users, $userSettings } = useStore($isDataPending);

    const [start, setStart] = useState(0);
    const [pageSize, setPageSize] = useState($userSettings.user.pageSize ? $userSettings.user.pageSize : 18);
    const [page, setPage] = useState(1);

    useEffect(() => {
        const paramsString = location.search;
        const searchParams = new URLSearchParams(paramsString);
        if (searchParams.has('page')) {
            const _page = Number(searchParams.get('page'))
            setPage(_page)
            setStart(((_page - 1) * pageSize))

        } else {
            setStart(0)
            setPage(1)
        }


    }, [location.search, pageSize])


    function changePagination(page) {
        if (page !== 1) {
            push({
                pathname: '/users',
                search: `page=${page}`
            })
        } else {
            push('/users')
        }
    }

    function changeShowSize(current, size) {
        setPageSize(size)
        const payload = { pageSize: size }
        userPageSizeSettingsChange(payload);
    }


    const updateData = useCallback(() => {
        if (search !== null) {
            const params = {
                length: pageSize,
                start: start,
                "search.value": search,
                sort: sortValue.id
            };
            usersPageMounted(params);
        }
    },  [pageSize, start, search, sortValue])

    useEffect(() => {
        updateData()
    }, [updateData]);


    return (
        <Container>
            <Row type='flex' gutter={active ? [25, 25] : [10, 10]}>
                {
                    $users.data && $users.data.data ? $users.data.data.map((user, i) =>
                        <Col xxl={active ? 4 : 24} xl={active ? 4 : 24} sm={active ? 8 : 24} xs={24} key={i}>
                            <User active={active} key={i} updateData={updateData} data={user}/>
                        </Col>
                    ) :
                        <Col span={24}>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </Col>
                }
            </Row>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15, marginBottom: 15 }}>
                <Pagination
                    pageSize={pageSize}
                    pageSizeOptions={['18', '30', '42', '54', '66', '78', '90']}
                    total={$users.data.recordsFiltered}
                    onChange={changePagination}
                    onShowSizeChange={changeShowSize}
                    current={page}
                    showSizeChanger={true}
                    // hideOnSinglePage={true}
                    showLessItems={true}
                />
            </div>
        </Container>
    )
};


