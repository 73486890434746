import React, { Fragment, useState } from 'react'
import { BodyHead } from "../../../components/body-head";
import { fakeData } from "../helper";
import { useFilter } from "../hook";
import { RequestContent } from "../organisms";
import { RequestFilter } from "../organisms";
import { SortSystem } from '../../../ui/molecules';

export const RequestPage = () => {
    const [value, setValue] = useState({ id: '', label: 'по ID' })
    const sortData = [
        {
            id: '',
            label: 'по ID'
        },
        {
            id: 'status',
            label: 'по статус'
        },
        {
            id: 'domain',
            label: 'по тип'
        },
    ]

    return (
        <Fragment>
            <BodyHead title='Запросы'>
                <SortSystem sortData={sortData} value={value} setValue={setValue} />
            </BodyHead>
            <RequestContent sortValue={value}/>
        </Fragment>
    )
}
