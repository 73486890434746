import React from 'react'
import { Container, AntTable } from '../../../ui/atoms'
import { Link } from 'react-router-dom';
import { useStore } from 'effector-react';
import { $station } from '../../../models';

export const StationSubList = () => {
    const {$stationSubList} = useStore($station)

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: 70,
            render: id => (<Link to=''>{id}</Link>)
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            render: props => (<Link to=''>{props.name}</Link>)
        },
        {
            title: 'Длина',
            dataIndex: 'length',
            key: 'length',
            render: props => (<Link to=''>{props.name}</Link>)
        },
        {
            title: 'Начало (км)',
            dataIndex: 'startPoint',
            key: 'startPoint',
            render: props => (<Link to=''>{props.name}</Link>)
        },
        {
            title: 'Отсчёт (м)',
            dataIndex: 'countdownPoint',
            key: 'countdownPoint',
            render: props => (<Link to=''>{props.name}</Link>)
        },
     
    ];

    return (
        <Container>
            <AntTable
                loading={$stationSubList.loading}
                columns={columns}
                dataSource={$stationSubList.data}
            />
        </Container>
    )
}