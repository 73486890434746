import styled from 'styled-components';
import { GridBasic, Button } from '../../../ui/atoms';


export const OrganizationHead = styled.div`
    display: inline-flex;
    align-items: center;
`;

export const PermissionActionWrap = styled.div`
    position: absolute;
    right:0;
    bottom: 5px;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 20px;
    opacity: 0;
    transition: .4s;
`

export const PermissionGrid = styled(GridBasic)`
    padding-bottom: 40px;
    position: relative;

    &:hover ${PermissionActionWrap} {
        opacity: 1;
        transform: translateX(0);
    }
`

export const PermissionActionBtn = styled(Button)`
    padding: 0 10px !important;
    font-size: 11px !important;
    height: 30px !important;

    svg {
        width: 16px;
        height: 16px
    }
`
