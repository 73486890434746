import styled from "styled-components";
import {Dropdown} from "antd";

export const SortBlock = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    
    span {
        margin-right: 10px;
        display: inline-block;
    }

    @media (max-width: 1440px) {
        span {
            display: ${({responsive}) => responsive && responsive.text && responsive.text ? 'none' : 'inline-block'};
        }
    }
`


export const SortLink = styled(Dropdown)`    
    color: ${({theme}) => theme.main.semanticColor.basic[600]}
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    
    div {
        display: flex;
        align-items: center;
        color: ${({theme}) => theme.main.semanticColor.basic[800]};
        white-space: nowrap;
        
        svg {
            margin: 0 0 0 5px
        }

        @media (max-width: 1440px) {
          font-size: 11px;
            svg {
                transform: scale(.8);
                margin: 0 !important;
            }
        }
    }
     svg {
        margin-right: 8px !important;
    }

    @media (max-width: 1440px) {
        font-size: 13px
    }
`
