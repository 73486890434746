import {httpDelete, httpGet} from "../../api";


export const getOrganizationList = (params) => httpGet({
    url: `/organization/list`, params
});

export const removeOrganization = (id) => httpDelete({
    url: `/organization/${id}`
});


