import React, { useEffect, useState, Fragment } from 'react'
import { BodyHead } from "../../../components/body-head";
import { useParams, useHistory } from 'react-router-dom'
import { FilterSelect } from "../../users/atoms";
import { Button, GridColumn } from "../../../ui/atoms";
import { PlusSvg, SectorsSvg, StationSvg } from "../../../media";
import { DropdownView } from "../../../ui/molecules";
import { StationList } from "../organisms";

export const Stations = () => {
    const {push} = useHistory()
    const {id} = useParams()
    
    const addMenuData = [
        {
            id: 'addSub',
            label: 'Перегон',
            action: () => push(`/sections/${id}/sub/add`)
        },
        {
            id: 'addSection',
            label: 'Станция',
            action: () => push(`/sections/${id}/stations/add`)
        }
    ]

    return (
        <Fragment>
            <BodyHead goBack={false} back={`sections/${id}/sub`} title='Перегон'>
                <GridColumn column={2} gap={40}>
                    <FilterSelect column={2} gap={40}>
                        <Button type='link'
                            onClick={() => push(`/sections/${id}/sub`)}>
                            <SectorsSvg /> Перегон
                        </Button>
                        <Button type='link'
                            active='true'
                            onClick={() => push(`/sections/${id}/stations`)}>
                            <StationSvg /> Станции
                        </Button>
                    </FilterSelect>
                    <DropdownView menuData={addMenuData}>
                        <Button>
                            <PlusSvg />Добавить
                        </Button>
                    </DropdownView>
                </GridColumn>
            </BodyHead>
            <StationList />
        </Fragment>
    )
}
