import {useCallback, useState} from "react";
import Cookies from "js-cookie";
import {deleteUserRole} from "../../models/user-model";

export const useUserProfile = () => {

    const [modalView, setModalView] = useState(null);
    const [statusModal, setStatusModal] = useState(false);

    const logoutSystem = useCallback(() => {
        Cookies.remove('token');
        deleteUserRole()
        if (!(window.location.pathname === '/sign-in')) {
            window.location.replace('/sign-in');
        }
    }, []);

    return {modalView, statusModal, setStatusModal, logoutSystem, setModalView}
};