import styled from 'styled-components';

export const BodyHeadTitle = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #8F9BB3;
  align-items: center;
  margin-right: 20px;
  
  input {
    margin-left: 15px;
    width: 300px;
  }

  @media (max-width: 1440px){
    font-size:15px;

    input {
      width: 220px;
      padding: 4px 14px !important;
      font-weight: 500;
      font-size: 12px;
    }

    svg {
      width: 18px;
      height: 18px;
    }
}
`;
