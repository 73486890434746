import React, {Fragment} from 'react'
import {UserName, UserProfileWrapper} from "../atoms";
import {HeaderAvatar} from "../molecules";
import {ProfileMenu} from "../molecules";
import {useSignedUserInfo} from "../../../hooks/common/signed-user";

export const UserProfile = () => {
    const {$signedUserInfo} = useSignedUserInfo()
    return (
        <Fragment>
            {!$signedUserInfo.loading
                ? <Fragment>
                    <ProfileMenu logout={true}>
                        <UserProfileWrapper>
                            <UserName>
                                Админ
                            </UserName>
                            <HeaderAvatar/>
                        </UserProfileWrapper>
                    </ProfileMenu>
                </Fragment>
                : null
            }
        </Fragment>
    )
}
