import React, { useEffect, useState, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { CategoriesList } from '../organisms';
import { BodyHead } from "../../../components/body-head";
import { Button, GridColumn } from "../../../ui/atoms";
import { PlusSvg } from "../../../media";

const CategoriesMainPageView = (props) => {
    const {
        history
    } = props;

    const [search, setSearch] = useState(null);

    useEffect(() => {
        const paramsString = history.location.search;
        const searchParams = new URLSearchParams(paramsString);
        if (searchParams.has('search')) {
            setSearch(searchParams.get('search'))
        } else {
            setSearch('')
        }

    }, [history.location.search])

    return (
        <Fragment>
            <BodyHead search={search} searchQuery={{ pathname: '/categories' }} setSearch={setSearch} title='Категории'>
                <GridColumn column={1} gap={0}>
                    <Button onClick={() => history.push('/categories/add')}><PlusSvg />Добавить</Button>
                </GridColumn>
            </BodyHead>
            <CategoriesList search={search} />
        </Fragment>
    )
};

export const CategoriesMainPage = withRouter(CategoriesMainPageView);
