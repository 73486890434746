import React, { useEffect, useState } from "react";
import { SectorsForm } from "../atoms/section";
import { Button, ButtonCenter, GridBasic, GridItem } from "../../../ui/atoms";
import { InputSystem } from "../../../ui/molecules";
import { withRouter } from "react-router-dom";
import { createPosition, getPositionInfo } from "../api";
import { notiPopup } from "../../../components/notification";
import { useLastLocation } from "react-router-last-location";


const SectorsFormBlockView = (props) => {
    const {
        history,
        match
    } = props;
    const lastLocation = useLastLocation();

    const [name, setName] = useState(undefined);

    const createSector = () => {

        const data = {
            title: name
        };
        if (match.params.id) {
            data.id = match.params.id
        }
        createPosition(data)
            .then(response => {
                if (response.status === 200) {
                    notiPopup(
                        'success',
                        `Должность ${match.params.id ? 'редактирован' : 'добавлен'}`
                    )

                    if (match.params.id) {
                        if (lastLocation) {
                            history.push({
                                pathname: lastLocation.pathname,
                                search: lastLocation.search
                            })
                        } else {
                            history.push(`/positions`)
                        }
                    }
                }
            })
            .catch(error => {
                console.error(error.response.data);
                notiPopup(
                    'error',
                    `Ошибка при ${match.params.id ? 'редактировании' : 'добавлении'}, попробуйте позже`
                )
            })
    };

    useEffect(() => {
        if (match.params.id) {
            getPositionInfo(match.params.id)
                .then(response => {
                    if (response.status === 200) {
                        const data = response.data;
                        setName(data.title);
                    }
                })
                .catch(error => console.log(error.response))
        }
    }, [match.params.id]);

    return (
        <SectorsForm>
            <GridBasic perColumn={1} rowGap={40} columnGap={24}>
                <GridItem>
                    <InputSystem
                        value={name}
                        change={setName}
                        type='text'
                        placeholder='Введите название должности'
                        label='Название'
                    />
                </GridItem>
            </GridBasic>
            <ButtonCenter>
                <Button onClick={() => history.push('/')} type='link'>Отмена</Button>
                <Button
                    disabled={
                        !name
                    }
                    onClick={() => createSector()}>Добавить</Button>
            </ButtonCenter>
        </SectorsForm>
    )
};

export const SectorsFormBlock = withRouter(SectorsFormBlockView);
