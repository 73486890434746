import React from 'react';
import { useStore } from 'effector-react';
import { $request } from '../models';

export const RequestValue = (props) => {
    const { type, id } = props
    const { $RequestValueInfo } = useStore($request)
    return (
        <>
            {type && id && type === 'position'
                ? $RequestValueInfo.positionInfo &&  Object.keys($RequestValueInfo.positionInfo).length > 0 && $RequestValueInfo.positionInfo[id] && $RequestValueInfo.positionInfo[id].title 
                : $RequestValueInfo.orgInfo && Object.keys($RequestValueInfo.orgInfo).length > 0 && $RequestValueInfo.orgInfo[id] && $RequestValueInfo.orgInfo[id].name}
        </>
    )
}