import { notification } from 'antd';

export const notiPopup = (type, description) => {
    let message = ''

    if(type === 'success'){
        message = 'Успешно!'
    }else if (type === 'error'){
        message = 'Ошибка!'
    }else if (type === 'warning'){
        message = 'Предупреждение!'
    }

    return notification[type]({
        message: message,
        description: description,
        duration: 1.5
    })
}

