import {httpGet, httpPut} from "../api";


export const getOrganizationList = (params) => httpGet({
    url: '/organization/list', params
});

export const getOrganizationInfo = (id) => httpGet({
    url: `/organization/${id}`
});

export const getOrganizationSectorList = (id) => httpGet({
    url: `/sector/organization/${id}`

});

export const getOrgUserList = (id) => httpGet({
    url: `/permission/${id}`
});

export const getSectorsByOrganization = (orgId) => httpGet({
    url: `permission/sector/${orgId}`
})

export const getUserPermission = (id) => httpGet({
    url: `permission/${id}/user`
})

export const addUserPermission = (data) => httpPut({
    url: `/permission/${data.id}/user`, data
});