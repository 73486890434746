import React, {useState} from 'react';
import Popover from "react-tiny-popover";
import {PopConfirmContent} from "../../../components/pop-confirm/atoms";
import {EditSvg, MoreHorizontalSvg, TrashSvg} from "../../../media";
import {deleteConfirm} from "../../../ui/molecules";
import {notiPopup} from '../../../components/notification';
import {useHistory, useParams} from 'react-router-dom'
import {deleteStation} from "../../../api/station-api";


export const PopoverItem = ({updateList, stationId}) => {
  const {push} = useHistory()
  const {id} = useParams()
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const removeStation = async () => {
    try {
      const res = await deleteStation(stationId)
      if (res.status === 200) {
        notiPopup('success', 'Станция удалена.')
        setIsPopoverOpen(false)
        updateList()
      }
    } catch (e) {
      console.log(e);
      notiPopup('error', 'Ошибка при удалении, попробуйте позже.')
    }
  }

  return (
      <Popover
          isOpen={isPopoverOpen}
          onClickOutside={() => setIsPopoverOpen(false)}
          position={'bottom'} // preferred position
          content={(
              <PopConfirmContent>
                <span
                    onClick={() => push(`/sections/${id}/stations/${stationId}/edit`)}> <EditSvg/> Редактировать</span>
                <span className='delete' onClick={() => deleteConfirm(removeStation)}
                >
                        <TrashSvg/> Удалить
                    </span>
              </PopConfirmContent>
          )}
      >
        <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
          <MoreHorizontalSvg/>
        </div>
      </Popover>
  )
};

