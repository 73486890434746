import {useCallback, useEffect} from "react";
import {useParams} from "react-router-dom";
import {stationListMount} from "../../models";

export const useStationList = () => {
  const {id} = useParams()

  const getStationList = useCallback(() => {
    if (id) {
      stationListMount(id)
    }
  }, [id])

  useEffect(() => {
    getStationList()
  }, [getStationList])


  return {getStationList}
}