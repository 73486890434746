import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import Popover from "react-tiny-popover";
import { PopConfirmContent } from "../../../components/pop-confirm/atoms";
import { EditSvg, LockSvg, MoreHorizontalSvg, TrashSvg } from "../../../media";
import { blockedUser, deleteUser } from "../api";
import { deleteConfirm } from "../../../ui/molecules";
import { notiPopup } from '../../../components/notification';


const PopoverItemView = (props) => {
    const {
        userInfo,
        history,
        location,
        userStatus,
        updateData
    } = props;


    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const deleteUserId = () => {
        deleteUser(userInfo.username)
            .then(response => {
                if (response.status === 200) {
                    notiPopup(
                        'success',
                        'Пользователь удален.'
                    )
                    history.push(location.pathname)
                    updateData()
                    setIsPopoverOpen(false)
                }
            })
            .catch(error => {

                notiPopup(
                    'error',
                    'Ошибка при удалении, попробуйте позже.'
                )
            })
    };

    const userBlocked = () => {
        const data = {
            ...userInfo,
            status: userStatus === 'ACTIVE' ? 'BLOCKED' : 'ACTIVE'
        };
        blockedUser(data)
            .then(response => {
                if (response.status === 200) {
                    notiPopup(
                        'success',
                        `Пользователь ${userStatus === 'ACTIVE' ? 'заблокирован' : 'разблокирован'}.`
                    )
                    history.push(location.pathname + location.search)
                    updateData()
                    setIsPopoverOpen(false)
                }
            })
            .catch(error => {
                notiPopup(
                    'error',
                    'Ошибка, попробуйте позже.'
                )
            })
    };


    return (
        <Popover
            isOpen={isPopoverOpen}
            onClickOutside={() => setIsPopoverOpen(false)}
            position={'bottom'} // preferred position
            content={(
                <PopConfirmContent>
                    <span onClick={() => history.push(`/users/${userInfo.username}/edit`)}> <EditSvg /> Редактировать</span>
                    <span onClick={() => userBlocked()}><LockSvg /> {userStatus === 'ACTIVE' ? 'Заблокировать' : 'Разблокировать'}</span>
                    <span
                        onClick={() => deleteConfirm(deleteUserId)}
                    >
                        <TrashSvg /> Удалить
                    </span>
                </PopConfirmContent>
            )}
        >
            <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                <MoreHorizontalSvg />
            </div>
        </Popover>
    )
};

export const PopoverItem = withRouter(PopoverItemView);
