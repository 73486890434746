import React from 'react'
import {InputSystem} from "../../../ui/molecules";
import {GridItem, ButtonCenter, Container, FormSection, GridBasic} from "../../../ui/atoms";
import {Button} from "../../../ui/atoms";
import {useStationForm} from "../../../hooks";
import {useHistory, useParams} from "react-router-dom";

export const StationForm = () => {
  const {push} = useHistory()
  const {id} = useParams()

  const {
    setCoverage,
    setName,
    coverage,
    name,
    sendForm,
    loading,
    startKm,
    setStartKm,
    startMeter,
    setStartMeter,
    stopKm,
    setStopKm,
    stopMeter,
    setStopMeter,
    sectorInfo
  } = useStationForm()


  return (
      <Container padding={24}>
        <FormSection>
          <GridBasic perColumn={2} rowGap={40} columnGap={24}>
            <GridItem gridColumn='1/3'>
              <InputSystem
                  type='text'
                  label='Название станции'
                  placeholder='Введите название станции'
                  value={name}
                  change={setName}
              />
            </GridItem>
            <GridItem>
              <InputSystem
                  type='text'
                  label='Направление'
                  value={sectorInfo.name ? sectorInfo.name : ''}
                  disabled={true}
              />
            </GridItem>
            <GridItem>
              <InputSystem
                  type='number'
                  label='Диаметр станции'
                  placeholder='Введите диаметр станции'
                  value={coverage}
                  change={setCoverage}
              />
            </GridItem>
            <GridItem>
              <InputSystem
                  type='number'
                  label='Входной светофор (км)'
                  placeholder='Введите входной километр'
                  value={startKm}
                  change={setStartKm}
              />
            </GridItem>
            <GridItem>
              <InputSystem
                  max={999}
                  type='sector'
                  label='Входной светофор (м)'
                  placeholder='Введите входной метр'
                  value={startMeter}
                  change={setStartMeter}
              />
            </GridItem>
            <GridItem>
              <InputSystem
                  type='number'
                  label='Выходной светофор (км)'
                  placeholder='Введите выходной километр'
                  value={stopKm}
                  change={setStopKm}
              />
            </GridItem>
            <GridItem>
              <InputSystem
                  type='sector'
                  max={999}
                  label='Выходной светофор (м)'
                  placeholder='Введите выходной метр'
                  value={stopMeter}
                  change={setStopMeter}
              />
            </GridItem>
          </GridBasic>
          <ButtonCenter>
            <Button onClick={() => push(`/sections/${id}/stations`)} type='link'>Отмена</Button>
            <Button
                onClick={sendForm}
                loading={loading}
                disabled={
                  !coverage
                  || !name
                }
            >
              Добавить
            </Button>
          </ButtonCenter>
        </FormSection>
      </Container>
  )
}
