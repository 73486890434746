import styled from "styled-components";
import {Dropdown} from "antd";
import {REQUEST_STATUS} from "../hook";

export const RequestSection = styled.div`
    margin: 0 24px;
`

export const TableItem = styled.div`
     padding: 16px;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;

        @media (max-width: 1440px){
            font-size: 11px;
            line-height: 1.3;
            padding: 12px 14px
        }
        
        svg {
        margin: 0 8px;
          color: ${({theme}) =>  theme.main.semanticColor.basic[600]};
          @media (max-width: 1440px){
            transform: scale(.8)
         } 
        }
        color: ${(props) => {
    if (props.type === undefined) {
        return props.theme.main.semanticColor.basic[800]
    } else {
        if (props.type === REQUEST_STATUS.NEW || props.type === null) {
            return props.theme.main.semanticColor.info[500]
        } else if (props.type === REQUEST_STATUS.SATISFIED) {
            return props.theme.main.semanticColor.success[500]
        } else if (props.type === REQUEST_STATUS.DECLINED) {
            return props.theme.main.semanticColor.danger[500]
        }
    }
}}
`

export const SortLink = styled(Dropdown)`    
    color: ${({theme}) => theme.main.semanticColor.basic[600]};
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    
    div {
        margin-left: 5px;
        display: flex;
        align-items: center;
        color: ${({theme}) => theme.main.semanticColor.basic[800]}
        
        svg {
            margin: 0 0 0 5px
        }
    }
     svg {
        margin-right: 10px;
    }
    
`
