import React, { useState, useCallback, useEffect } from 'react';
import { GridItem, ErrorText } from '../../../ui/atoms';
import { SelectSystem } from '../../../ui/molecules/select';
import { InputSystem } from '../../../ui/molecules';
import { useParams } from 'react-router-dom';
import { getSectorsByOrganization } from '../../../api/organization-api';
import { getSubSectorList } from '../../../api/sector-api';
import { PermissionGrid } from '../atoms';
import { PermissionActionWrap, PermissionActionBtn } from '../atoms';
import { PlusSvg, TrashSvg } from '../../../media';

export const UserPermissionItem = (props) => {
    const { idx, change, error, start, end, _sectorId, _subSectorId, addItem, deleteItem } = props
    const { id } = useParams()

    const [sectorId, setSectorId] = useState(undefined)
    const [sectorList, setSectorList] = useState([])
    const [subSectorId, setSubSectorId] = useState(undefined)
    const [subSectorList, setSubSectorList] = useState([])
    const [sectorLoading, setSectorLoading] = useState(false)
    const [subSectorLoading, setSubSectorLoading] = useState(false)
    const [subSectorSearch, setSubSectorSearch] = useState('')
    const [check, setCheck] = useState(0)
    const [edit, setEdit] = useState(true)

    const filteredSector = useCallback((arr) => {
        const tmp = []
        for (let i = 0; i < arr.length; i++) {

            tmp.push({
                value: arr[i].sector ? arr[i].sector.id : arr[i].id,
                label: arr[i].sector ? arr[i].sector.name : arr[i].name
            })
        }
        return tmp
    }, [])

    

    const sectorInfo = useCallback((arr, id) => {
        let tmp = {}
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].sector && arr[i].sector.id === id) {
                tmp = { ...arr[i].sector }
            } else {
                if (arr[i].id === id) {
                    tmp = { ...arr[i] }
                }
            }
        }
        return tmp
    }, [])

    const getSectors = useCallback(async () => {
        setSectorLoading(true)
        try {
            const res = await getSectorsByOrganization(id)
            setSectorList(res.data)
            setSectorLoading(false)
            setCheck(Math.random())
        } catch (e) {
            console.log(e);
            setSectorLoading(false)
        }
    }, [id])

    const getSubSectors = useCallback(async (id, search = '') => {
        setSubSectorLoading(true)
        const params = {
            id: id,
            params: {
                perPage: 20,
                'search.value': search,
                all: true
            }
        }
        try {
            const res = await getSubSectorList(params)
            setSubSectorList(res.data.data)
            setSubSectorLoading(false)
            setCheck(Math.random())
        } catch (e) {
            console.log(e);
            setSubSectorLoading(false)
        }
    }, [])

    const handleSearch = useCallback((e) => {
        if (sectorId) {
            setSubSectorSearch(e)
            getSubSectors(sectorId, e)
        }
    }, [sectorId, getSubSectors])


    const handleChange = useCallback((value, type) => {
        if (type === 'sector') {
            setSectorId(value)
            getSubSectors(value)
            const sector_info = sectorInfo(sectorList, value)
            change(sector_info, idx, 'sectorInfo')
        }
        if (type === 'subSector') {
            const sector_info = sectorInfo(subSectorList, value)
            const start = (sector_info.startPoint * 1000) + sector_info.countdownPoint
            const end = (sector_info.startPoint * 1000) + sector_info.countdownPoint + sector_info.length
            change(sector_info, idx, 'subSectorInfo')

            if (Object.values(sector_info).length > 0) {
                change(start, idx, 'start')
                change(end, idx, 'end')
            } else {
                change(0, idx, 'start')
                change(0, idx, 'end')
            }

            setSubSectorId(value)
        }

        if (type === 'start') {
            change(value, idx, type)
        }
        if (type === 'end') {
            change(value, idx, type)
        }
    }, [change, sectorList, subSectorList, idx, sectorInfo, getSubSectors])

    const handleDelete = () => {
        deleteItem(idx)
    }

    useEffect(() => {
        getSectors()
    }, [getSectors])

    useEffect(() => {
        if (_sectorId) {
            setSectorId(_sectorId)
        }
    }, [_sectorId])

    useEffect(() => {
        if (_sectorId && _subSectorId) {
            setSubSectorId(_subSectorId)
            getSubSectors(_sectorId, _subSectorId)
        }
    }, [_sectorId, _subSectorId, getSubSectors])


    return (
        <PermissionGrid perColumn={2} rowGap={40} columnGap={24}>
            <GridItem>
                <SelectSystem
                    change={(e) => handleChange(e, 'sector')}
                    type='text'
                    placeholder='Выберите направление'
                    label='Направление'
                    value={sectorId}
                    options={filteredSector(sectorList)}
                    loading={sectorLoading}
                />
                {
                    error && error.sector && <ErrorText>{error.sector}</ErrorText>
                }
            </GridItem>
            <GridItem>
                <SelectSystem
                    change={(e) => handleChange(e, 'subSector')}
                    showSearch={true}
                    type='text'
                    placeholder='Выберите перегон'
                    label='Перегон'
                    value={subSectorId}
                    options={filteredSector(subSectorList)}
                    loading={subSectorLoading}
                    onSearch={(e) => handleSearch(e)}
                    disabled={!sectorId}
                />
                {
                    error && error.subSector && <ErrorText>{error.subSector}</ErrorText>
                }
            </GridItem>
            <GridItem>
                <InputSystem
                    disabled={!subSectorId}
                    value={start}
                    change={(e) => handleChange(e, 'start')}
                    type='number'
                    placeholder='Разрешенный старт'
                    label='Начало границы'
                />
                {
                    error && error.start && <ErrorText>{error.start}</ErrorText>
                }
            </GridItem>
            <GridItem>
                <InputSystem
                    disabled={!subSectorId}
                    value={end}
                    change={(e) => handleChange(e, 'end')}
                    type='number'
                    placeholder='Разрешенный конец'
                    label='Конец границы'
                />
                 {
                    error && error.end && <ErrorText>{error.end}</ErrorText>
                }
            </GridItem>
            <PermissionActionWrap>
                <PermissionActionBtn onClick={addItem}>
                    <PlusSvg />
                     добавить
                </PermissionActionBtn>
                <PermissionActionBtn type='error' onClick={handleDelete}>
                    <TrashSvg />
                    удалить
                </PermissionActionBtn>
            </PermissionActionWrap>
        </PermissionGrid>
    )
}