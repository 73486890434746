import React from 'react'
import {AntTable, Container} from "../../../ui/atoms";
import {useStationList} from "../../../hooks";
import {useStore} from "effector-react";
import {$station} from "../../../models";
import {PopoverItem} from "../moleculas";
import {Link, useParams} from 'react-router-dom';

export const StationList = () => {
  const {getStationList} = useStationList()
  const {$stationList} = useStore($station)
  const {id} = useParams()
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    render: (id, data) => (<Link to={`/sections/${id}/stations/sub/${data.id}`}>{id}</Link>)
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      render: (name, data) => (<Link to={`/sections/${id}/stations/sub/${data.id}`}>{name}</Link>)
    },
    {
      title: 'Направления',
      dataIndex: 'sector',
      key: 'sector',
      render: (sector, data) => (<Link to={`/sections/${id}/stations/sub/${data.id}`}>{sector}</Link>)
    },
    {
      title: 'Ширина станции',
      dataIndex: 'coverage',
      key: 'coverage',
      render: (coverage, data) => (<Link to={`/sections/${id}/stations/sub/${data.id}`}>{coverage}</Link>)
    },
    {
      title: 'Входной светофор',
      dataIndex: 'start',
      key: 'start',
      render: (start, data) => (<Link to={`/sections/${id}/stations/sub/${data.id}`}>{start}</Link>)
    },
    {
      title: 'Выходной светофор',
      dataIndex: 'end',
      key: 'end',
      render: (end, data) => (<Link to={`/sections/${id}/stations/sub/${data.id}`}>{end}</Link>)
    },
    {
      title: '',
      dataIndex: 'popover',
      key: 'popover',
      width: 70,
      render: (id, data) => (<PopoverItem updateList={getStationList} stationId={data.id}/>)
    }
  ];

  return (
      <Container>
        <AntTable
            loading={$stationList.loading}
            columns={columns}
            dataSource={$stationList.data}
        />
      </Container>
  )
}