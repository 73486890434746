import React, { useState, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { AddVariant, VariantTitle } from "../atoms";
import { GridItem, Button, ButtonCenter, FormSection, Container, GridBasic, GridColumn } from "../../../ui/atoms";
import { InputSystem } from "../../../ui/molecules";
import { PlusSvg, TrashSvg } from "../../../media";
import { addListToCategory } from "../api";
import { notiPopup } from '../../../components/notification';


const AddListCategoriesView = (props) => {
    const {
        history,
        match
    } = props;

    const [name, setName] = useState('');
    const [variants, setVariants] = useState([
        {
            name: '',
            value: 0
        },
        {
            name: '',
            value: 1
        }
    ]);
    const [check, setCheck] = useState(false);


    const CreateCategories = () => {
        const data = {
            name: name,
            values: variants
        };
        addListToCategory(match.params.id, data)
            .then(response => {
                if (response.status === 200) {
                    setName('');
                    setVariants([
                        {
                            name: '',
                            value: 0
                        },
                        {
                            name: '',
                            value: 1
                        }
                    ]);

                    notiPopup(
                        'success',
                        'Список добавлен'
                    )
                }
            })
            .catch(error => {
                notiPopup(
                    'error',
                    'Ошибка при добавлении, попробуйте позже'
                )
            });
    };


    const ChangeVariant = (value, index, type) => {
        let data = variants;
        data[index][type] = value;
        setVariants(data);
        setCheck(!check);
    };
    const addItemVariant = () => {
        let data = variants;
        data.push({ name: '', value: data.length });
        setVariants(data);
        setCheck(!check);
    };

    const deleteItemVariants = (idx) => {
        const data = variants
        const newData = [...data.slice(0, idx), ...data.slice(idx + 1)]
        setVariants(newData)
    }

    return (
        <Container>
            <FormSection>
                <GridBasic perColumn={1} rowGap={40} columnGap={24}>
                    <GridItem>
                        <InputSystem
                            value={name}
                            change={setName}
                            type='text'
                            placeholder='Введите название'
                            label='Название'
                        />
                    </GridItem>
                    <GridItem>
                        <AddVariant>
                            Добавить вариант
                            <Button noChildren onClick={() => addItemVariant()}><PlusSvg /></Button>
                        </AddVariant>
                    </GridItem>
                    {
                        variants.map((variant, i) =>
                            <Fragment key={i}>
                                <GridItem>

                                    <GridColumn column={2} gap={25}>
                                        <VariantTitle>Вариант № {i + 1}</VariantTitle>
                                        <Button type='error' noChildren onClick={() => deleteItemVariants(i)}><TrashSvg /></Button>
                                    </GridColumn>
                                </GridItem>
                                <GridItem>
                                    <InputSystem
                                        value={variant.name}
                                        change={(e) => ChangeVariant(e, i, 'name')}
                                        type='text'
                                        placeholder='Введите название'
                                        label='Название'
                                    />
                                </GridItem>
                            </Fragment>
                        )
                    }
                </GridBasic>
                <ButtonCenter>
                    <Button onClick={() => history.push('/categories/list')} type='link'>Отмена</Button>
                    <Button
                        disabled={
                            !name
                        }
                        onClick={() => CreateCategories()}>Добавить</Button>
                </ButtonCenter>
            </FormSection>

        </Container>)
};
export const AddListCategories = withRouter(AddListCategoriesView);
